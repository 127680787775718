import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CordovaService } from './services/cordova.service';
import { Router } from '@angular/router';
import { RoutingStateService } from './services/routing-state.service';
import { ConnectionService } from './services/connection.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    public title = 'SCIPApp';

    constructor(
        private translate: TranslateService
        , private cordova: CordovaService
        , private router: Router
        , private routingState: RoutingStateService,
        private connectionService: ConnectionService,
    ) {
        translate.setDefaultLang('de');
        translate.currentLang = translate.defaultLang;
        routingState.loadRouting();
    }

    ngOnInit() {
        this.connectionService.checkInternetConnection();

        if (this.cordova.isCordova) {
            document.addEventListener('deviceready', () => {
                this.startApp();
            }, false);
        } else {
            this.startApp();
        }
    }

    startApp() {
        this.cordova.hideSplashScreen();
    }
    useLanguage(language: string) {
        this.translate.use(language);
    }

}
