import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { TaskService } from 'src/app/services/task.service';
import { Task } from '../../task';


@Component({
  selector: 'app-task-export-dialog',
  templateUrl: './task-export-dialog.component.html',
  styleUrls: ['./task-export-dialog.component.scss']
})
export class TaskExportDialogComponent implements OnInit {
  public dataSource: any;
  public tasks: Task[] = [];
  public indexNumber: any;
  constructor(private taskService: TaskService, ) { }

  ngOnInit() {
    const user_profile = JSON.parse(localStorage.getItem('user_profile'));
    this.taskService.getMeasures('4', user_profile.company_id, user_profile.id, '5').subscribe(res => {
      this.dataSource = res.data.measuresData;
      this.indexNumber = this.dataSource.length;
    });
  }

  public export() {
    let i = 0;
    const readyToExport = [this.dataSource[i++]];
    const workBook = XLSX.utils.book_new(); 
    const workSheet = XLSX.utils.json_to_sheet(readyToExport);

    XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); 
    XLSX.writeFile(workBook, 'temp.xlsx');
  }


}
