import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public fPForm: FormGroup;
  public error: string;
  public email: any;
  public response: any;
  
  constructor(private fb: FormBuilder, private _router: Router, private translate: TranslateService,
    private auth: AuthenticationService) { }

  ngOnInit() {
    this.fPForm = this.newForm();
  }
  newForm(): FormGroup {
    return this.fb.group({
      forgotEmail: ['', [Validators.required, Validators.email]],
    });

  }

  onSubmit(forgotEmail: string) {
    this.email = {
      username: forgotEmail
    }
    this.auth.forgotPassword(this.email).subscribe(res => {
      alert('Email sent  for reset password');
      this._router.navigate(['/'])
    })
  }


}
