import {Component, Input, Output, EventEmitter, ViewChild, AfterViewInit, HostListener, OnInit} from '@angular/core';
import { Options } from 'ng5-slider';
import {UserService} from '../../../../services/user.service';
import {EventService} from '../../../../services/event.service';

@Component({
    selector: 'app-icon-slider-component',
    templateUrl: 'icon-slider.component.html',
    styleUrls: ['icon-slider.component.scss']
})

export class IconSliderComponent implements OnInit {
  private userProfile = JSON.parse(localStorage.getItem('user_profile'));
  value: any = 1;
  options: Options = {
    showTicksValues: true,
    showTicks: false,
    stepsArray: [
      {value: 1, legend: 'Small'},
      {value: 2, legend: 'Medium'},
      {value: 3, legend: 'Big'},
    ]
  };

    constructor(private userService: UserService, private eventService: EventService) { }

    ngOnInit() {
      this.value = this.options.stepsArray.find((obj) => {
        return obj.legend === this.userProfile.icons_size;
      }).value;
    }


  sliderEvent() {
    const size = this.options.stepsArray.find((obj) => {
      return obj.value === this.value;
    }).legend;
    this.userService.updateIconsSize(size)
      .subscribe((res) => {
        if (res.success) {
          let user = JSON.parse(localStorage.getItem('user_profile'));
          user.icons_size = size;
          localStorage.setItem('user_profile', JSON.stringify(user));
          this.eventService.sliderChanged('Slider changed!');
        }
      },
        error => console.error(error));
  }

}
