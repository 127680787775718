import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserProfile } from 'src/app/models/userProfile.model';
import { MessageService } from 'src/app/services/message.service';
import { Message } from 'src/app/models/message.model';
import { CookieService } from 'ngx-cookie-service';
import { Attachment } from 'src/app/models/attachment.model';
import { Chat } from 'src/app/models/chat.model';
declare var MediaRecorder: any;
import { TypeCheckHelper } from 'src/app/helpers/typeCheck.helper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-chat',
    templateUrl: './chat-component.html',
    styleUrls: ['./chat-component.scss']

})
export class ChatComponent implements OnInit {
    @Output()
    @Input() profileIdtest: any;
    @Input() chatTitles: any;
    @Input()
    set userData(userData: UserProfile) {
        this._userData = userData;
        if (userData) {
            this.update();
        }
    }
    public chatsCleared = new EventEmitter<boolean>();
    public messages: any;
    public message: string;
    public fileAttachment = [];
    public username = 'test-user';
    public isMessageRead = true;
    public isTranslate = false;
    public chats = [];
    public chunks = [];
    public selecteChat: Chat;
    public mediaRecorder: any;
    public recordStared = false;
    public audioUrl;
    private _userData: UserProfile;

    constructor(private messageService: MessageService, private cookies: CookieService, private typeCheckHelper: TypeCheckHelper,
        private snackBar: MatSnackBar, public sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        // TODO commented according to SCIPRB-85
        // this.messageService.openConnection();
        this.messages = this.messageService.messages;
    }

    private generateId(): string {
        return Math.random().toString(36).substr(2, 9);
    }

    public update() {
        const chat: Chat = {
            id: this.generateId(),
            messages: [],
            title: this._userData.name
        };
        if (this.chats.filter(x => x.title === chat.title).length > 0) {
            return;
        }
        this.chats.push(chat);
    }

    public onTabSelected(id: any) {
        this.selecteChat = this.chats[id.index];
    }

    public send() {
        const messagetmp: Message = {
            message: this.message,
            type: 'type',
            to: this.profileIdtest,
            from: this.username,
            time: new Date()
        };
        if (this.fileAttachment) {
            messagetmp.attachments = this.fileAttachment;
        }

        this.messageService.sendMessage(messagetmp);

        this.chats.find(x => x === this.selecteChat).messages = this.messageService.messages;
        this.fileAttachment = [];
        this.message = '';
    }

    public onFileUploadClick() {
        document.getElementById('file-input').click();
    }

    public download(attachment: Attachment) {
        const temporaryElement = document.createElement('a');
        document.body.appendChild(temporaryElement);
        temporaryElement.href = attachment.data.toString();
        temporaryElement.download = 'Attachment';
        temporaryElement.click();
    }

    public async readURL(event) {
        let isExtensionValid;
        await this.typeCheckHelper.check(event).then((response) => {
            isExtensionValid = response;
        });
        if (!isExtensionValid) {
            this.showErrorMessage('Wrong file extension');
            return;
        }
        if (event.target.files) {
            for (let i = 0; i < event.target.files.length; i++) {
                const reader = new FileReader();
                reader.readAsDataURL(event.target.files[i]);
                const f = event.target.files[i];
                const fileTmp: Attachment = {
                    fileName: f.name,
                    size: f.size,
                    type: f.type,
                    data: reader.result,
                    id: i,
                    thumbNail: 'https://via.placeholder.com/90x90'
                };
                reader.onload = () => {
                    fileTmp.data = reader.result;

                    if (fileTmp.type.indexOf('image') > -1) {
                        fileTmp.thumbNail = fileTmp.data;
                    }
                    this.fileAttachment.push(fileTmp);
                };
            }
        }
    }

    public closeTab(index: number) {
        this.chats.splice(index);
        if (this.chats.length === 0) {
            this.chatsCleared.emit(true);
        }
    }

    public removeAttachment(attachmentId) {
        if (this.fileAttachment.filter(x => x.id === attachmentId).length > 0) {
            this.fileAttachment.splice(attachmentId, 1);
        }
    }

    public async audioRecord() {
        this.recordStared = true;
        let stream = null;
        try {
            stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
            this.mediaRecorder = new MediaRecorder(stream);
            this.mediaRecorder.start();
            this.mediaRecorder.addEventListener('dataavailable', function (e) {
                this.chunks = [];
                if (e.data.size > 0) {
                    this.chunks.push(e.data);
                }
            });
        } catch (err) {

        }
    }
    public stop() {
        this.recordStared = false;
        this.mediaRecorder.stop();
        const attachment = this.fileAttachment;
        const _this = this;
        this.mediaRecorder.onstop = function (e) {
            const blob = new Blob(this.chunks, { 'type': 'audio/wav' });
            this.chunks = [];
            const reader = new FileReader();
            let fileTmp: Attachment;
            reader.readAsDataURL(blob);
            this.audioUrl = URL.createObjectURL(blob);
            reader.onloadend = function () {
                fileTmp = {
                    fileName: 'audio message',
                    size: blob.size,
                    type: 'audio/wav',
                    data: reader.result,
                    id: attachment.length,
                    thumbNail: 'https://image.flaticon.com/icons/png/512/122/122320.png'
                };
                _this.audioUrl = _this.sanitizer.bypassSecurityTrustResourceUrl(fileTmp.data);
                fileTmp.data = _this.audioUrl;
                attachment.push(fileTmp);
            };
        };

        this.fileAttachment = attachment;
    }

    private showErrorMessage(message?: string) {
        if (message) {
            this.snackBar.open(message, '', {
                duration: 2000,
            });
            return;
        }
        this.snackBar.open('Error occured', '', {
            duration: 2000,
        });
    }

}
