import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-imprint-dialog',
  templateUrl: './imprint-dialog.component.html',
  styleUrls: ['./imprint-dialog.component.scss']
})
export class ImprintDialogueComponent implements OnInit {

  constructor(private _router: Router,private translate:TranslateService) { }

  ngOnInit() {
    
  }
  dashboardDisplay(){
    this._router.navigate(['/dashboard'])
  }

}
