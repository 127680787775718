import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class TaskForms {
    public taskForm: FormGroup;

    constructor(private fb: FormBuilder) {
        this.taskForm = this.fb.group({
            title: ['', Validators.required],
            description: [''],
            date: ['', Validators.required],
            time: ['', Validators.required],
            responsible: ['', Validators.required],
            enableChat: [''],
            frequency: [''],
            type: ['', Validators.required]
          });
    }
}