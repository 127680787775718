import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MaterialModule } from 'src/app/material.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BottomComponent } from './bottom/bottom.component';
import { NodeDialogueTaskComponent } from './header/node-dialog-task/node-dialog-task.component';
import { BottomrunninglinksComponent } from './bottom/bottomrunninglinks/bottomrunninglinks.component';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { HeaderSettingComponent } from './header/header-setting/header-setting.component';
import { ImprintDialogueComponent } from './header/imprint-dialog/imprint-dialog.component';
import { SettingPrivacyDialogueComponent } from './header/setting-privacy-dialog/setting-privacy-dialog.component';
import { VideoComponent } from './components/video-component/video.component';
import { CarouselComponent } from './components/carousel-component/carousel.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { DocsComponent } from './components/docs-component/docs.component';
import { IconSliderComponent } from './components/icon-slider-component/icon-slider.component';
import { AudioComponent } from './components/audio-component/audio.component';
// import { NgxDocViewerModule } from 'ngx-doc-viewer';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import { Ng5SliderModule } from 'ng5-slider';

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    BottomComponent,
    NodeDialogueTaskComponent,
    BottomrunninglinksComponent,
    HeaderSettingComponent,
    ImprintDialogueComponent,
    SettingPrivacyDialogueComponent,
    VideoComponent,
    CarouselComponent,
    DocsComponent,
    IconSliderComponent,
    AudioComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SlickCarouselModule,
    Ng5SliderModule,
    // NgxDocViewerModule,

    RouterModule.forChild([
      {
        path: 'header',
        component: HeaderComponent
      },
      {
        path: 'bottom',
        component: BottomComponent
      },
      {
        path: 'sidebar',
        component: SidebarComponent
      },
    ]),
    PdfViewerModule
  ],
  exports: [
    SidebarComponent,
    HeaderComponent,
    BottomComponent,
    VideoComponent,
    CarouselComponent,
    NgxFileDropModule,
    DocsComponent,
    IconSliderComponent,
    AudioComponent
  ],
  entryComponents: [NodeDialogueTaskComponent],
  providers: [TranslateService],

})
export class SharedModule { }
