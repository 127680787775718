import { Component, OnInit, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl, } from '@angular/platform-browser';

@Component({
  selector: 'app-task-file-dialog',
  templateUrl: './task-file-dialog.component.html',
  styleUrls: ['./task-file-dialog.component.scss']
})
export class TaskFileDialogComponent implements OnInit {
  @Input()
  public fileUrl = "https://dev.scip.website/SmartlySolutions/API/data"
  public url: SafeResourceUrl;
  public id: string;
  public fileTask: any;
  public fileName: string;
  public fileExtension: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public sanitizer: DomSanitizer
  ) {
    this.fileTask = this.data.fileTask.measure_file_data;
  }

  ngOnInit() { }

  checkExt(link) {
    let linkArr = link.split('/');
    let filename = linkArr[linkArr.length - 1];
    let fileNameArr = filename.split('.');
    let fileExt = fileNameArr[fileNameArr.length - 1].toLowerCase();
    let type = 'Invalid';
    if (fileExt === "jpg" || fileExt === 'jpeg' || fileExt === 'png') {
      type = 'Image';
    }
    else if (fileExt === "mp4") {
      type = 'Video';
    }
    else if (fileExt === "pdf") {
      type = 'Pdf';
    }
    return type;
  }


}
