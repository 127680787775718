import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';
import { LoginComponent } from './component/login/login.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { FlexLayoutModule } from '@angular/flex-layout';

import { DashboardModule } from './component/dashboard/dashboard.module';
import { SharedModule } from './component/shared/shared.module';
import { CarouselModule } from 'primeng/carousel';
import { CordovaService } from './services/cordova.service';
import { OwlModule } from 'ngx-owl-carousel';

import { TranslateCompiler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { ForgotPasswordComponent } from './component/login/forgot-password/forgot-password.component';
import { AdvanceoverlayComponent } from './component/login/advanceoverlay/advanceoverlay.component';
import { PrivacyPolicyDialogComponent } from './welcome/privacy-policy-dialog/privacy-policy-dialog.component';
import { TaskService } from './services/task.service';
import { BottomrunninglinksComponent } from './component/shared/bottom/bottomrunninglinks/bottomrunninglinks.component';
import { FeedbackComponent } from './component/feedback/feedback.component';
import { FeedbackHeaderComponent } from './component/feedback/feedback-header/feedback-header.component';
import { FeedbackFormComponent } from './component/feedback/feedback-form/feedback-form.component';
import { DropdownModule } from 'primeng/dropdown';
import { SettingPrivacyDialogueComponent } from './component/shared/header/setting-privacy-dialog/setting-privacy-dialog.component';
import { ImprintDialogueComponent } from './component/shared/header/imprint-dialog/imprint-dialog.component';
import { TaskFileDialogComponent } from './component/dashboard/task-file-dialog/task-file-dialog.component';
import { TaskStatusDialogComponent } from './component/dashboard/task-status-dialog/task-status-dialog.component';
import { TaskUserDetailDialogComponent } from './component/dashboard/task-user-detail-dialog/task-user-detail-dialog.component';
import { LoginNfcComponent } from './component/login/login-nfc/login-nfc.component';
import { LoginQrComponent } from './component/login/login-qr/login-qr.component';
import { LoginTypeButtonComponent } from './component/login/login-type-button/login-type-button.component';
import { LoginEmailComponent } from './component/login/login-email/login-email.component';
import { PictureAnnotationComponent } from './component/dashboard/picture-annotation/picture-annotation.component';
import { PictureCameraComponent } from './component/dashboard/picture-annotation/picture-camera/picture-camera.component';
import { ConnectionService } from './services/connection.service';
import { AlertComponent } from './alert/alert.component';
import { AlertService } from './services/alert.service';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { NodesService } from './services/nodes.service';
import { CreateTaskComponent } from './component/dashboard/task/task-managment/create-task/create-task.component';
import { EditTaskComponent } from './component/dashboard/task/task-managment/edit-task/edit-task.component';
import { ViewTaskComponent } from './component/dashboard/task/task-managment/view-task/view-task.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TaskRejectDialogComponent } from './component/dashboard/task/task_moreOption/task-reject-dialog/task-reject-dialog.component';
import { TaskExportDialogComponent } from './component/dashboard/task/task_moreOption/task-export-dialog/task-export-dialog.component';
import { TaskArchiveDialogComponent } from './component/dashboard/task/task_moreOption/task-archive-dialog/task-archive-dialog.component';
import { TaskRemoveDialogComponent } from './component/dashboard/task/task_moreOption/task-remove-dialog/task-remove-dialog.component';
import { ProfileCardComponent } from './component/dashboard/activity/profile-card/profile-card.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NotFoundComponent } from './component/not-found/not-found.component';
import { ImageViewComponent } from './component/dashboard/activity/image-view/image-view.component';
import { CloseAlertComponent } from './component/dashboard/activity/close-alert/close-alert.component';
import { ChatComponent } from './component/dashboard/activity/chat/chat-component';
import { TaskFilterComponent } from './component/dashboard/task/task-managment/task-filter/task-filter.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { EditFavouriteComponent } from './component/dashboard/edit-favourite/edit-favourite.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {AccordionModule} from 'primeng/primeng';

@NgModule({
  declarations: [
    AppComponent, LoginComponent, WelcomeComponent, ForgotPasswordComponent,
    AdvanceoverlayComponent, PrivacyPolicyDialogComponent,
    FeedbackComponent, FeedbackHeaderComponent, FeedbackFormComponent,
    LoginNfcComponent, LoginQrComponent, LoginTypeButtonComponent,
    LoginEmailComponent, AlertComponent, NotFoundComponent, EditFavouriteComponent
  ],
  imports: [
    OwlModule,
    FormsModule, ReactiveFormsModule, BrowserModule,
    BrowserAnimationsModule, FlexLayoutModule, FormsModule,
    ReactiveFormsModule, MaterialModule, DashboardModule,
    SharedModule, AppRoutingModule, DropdownModule, CarouselModule, MatDatepickerModule,
    NgxFileDropModule,
    PdfViewerModule,

    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },

      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),

    AccordionModule,
  ],

  providers: [TranslateService,
    NodesService, TaskService, CookieService,
    CordovaService, ConnectionService, AlertService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    SettingPrivacyDialogueComponent, ForgotPasswordComponent, AdvanceoverlayComponent,
    PrivacyPolicyDialogComponent, BottomrunninglinksComponent, ImprintDialogueComponent,
    TaskFileDialogComponent, TaskStatusDialogComponent, TaskUserDetailDialogComponent,
    PictureAnnotationComponent, PictureCameraComponent,
    CreateTaskComponent, EditTaskComponent, EditFavouriteComponent,
    TaskRejectDialogComponent, TaskExportDialogComponent, TaskArchiveDialogComponent,
    ProfileCardComponent, ImageViewComponent, CloseAlertComponent, ChatComponent, TaskFilterComponent, ViewTaskComponent, TaskRemoveDialogComponent]

})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}
