import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Task } from '../../task';
@Component({
  selector: 'app-task-reject-dialog',
  templateUrl: './task-reject-dialog.component.html',
  styleUrls: ['./task-reject-dialog.component.scss']
})
export class TaskRejectDialogComponent {
  public recorderOff: boolean = false;
  public recorderOffA: boolean = true;
  public speechInput: string;
  public speechInputA: string;
  public action: string;
  public local_data: any;
  public task: Task[];
  constructor(public dialogRef: MatDialogRef<TaskRejectDialogComponent>,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: Task) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }

  doAction() {
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
  getSpeech() {
    // this.speech.record('en-US')
    //   .subscribe(e => this.speechInput = e);
    this.recorderOff = !this.recorderOff;
  }
  getSpeechA() {
    // this.speech.record('en-US')
    //   .subscribe(res => this.speechInputA = res);
    this.recorderOff = true;
    this.recorderOffA = !this.recorderOffA;
  }
  stopRecording() {
    // this.speech.stopRecord('en-US');
    this.recorderOff = false;
    this.recorderOffA = false;
  }
}
