import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TaskService } from 'src/app/services/task.service';

@Component({
  selector: 'app-task-user-detail-dialog',
  templateUrl: './task-user-detail-dialog.component.html',
  styleUrls: ['./task-user-detail-dialog.component.scss']
})
export class TaskUserDetailDialogComponent implements OnInit {
  public userDetails: any = {
    nodes: [],
    roles: [],
    userImage: ''
  };
  public noodes: any;
  public roles: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
     private taskService: TaskService) { }

  ngOnInit() {
    this.taskService.getUserDetail(this.data.id).subscribe(response => {
      this.userDetails = response;
    })
  }

}
