import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-close-alert',
    templateUrl: './close-alert.component.html',
    styleUrls: ['./close-alert.component.scss']

})
export class CloseAlertComponent implements OnInit {

    public imageSrc: string;

    constructor(private dialogRef: MatDialogRef<CloseAlertComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
    }

    close() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        this.dialogRef.close(true);
    }

    cancel() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        this.dialogRef.close(false);
    }

    save() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        this.dialogRef.close('save');
    }

}
