import { Component, OnInit } from '@angular/core';
import { CordovaService } from '../../../services/cordova.service';

@Component({
  selector: 'app-login-qr',
  templateUrl: './login-qr.component.html',
  styleUrls: ['./login-qr.component.scss']
})
export class LoginQrComponent implements OnInit {

    constructor(
        private cordova: CordovaService
    ) {}

    ngOnInit() {}

    scanQrCode() {
      if (this.cordova.isBarcodeScanner) {
          this.cordova.scanQrCode(this.scanQrCodeCallback);
      } else {
          this.scanQrCodeCallback(new Error('no isBarcodeScanner'));
      }
    }

    scanQrCodeCallback = (error: Error, result?: string) : void => {
      if (error === null) {
          alert('Result: ' + result);
      } else {
        alert('Error: ' + error.message);
      }
    }
}
