import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WelcomeComponent } from './welcome/welcome.component';
import { LoginComponent } from './component/login/login.component';
import { FeedbackComponent } from './component/feedback/feedback.component';
import { environment } from '../environments/environment';
import { NotFoundComponent } from './component/not-found/not-found.component';
import { EditFavouriteComponent } from './component/dashboard/edit-favourite/edit-favourite.component';

const routes: Routes = [
  {
    path: '',
    component: WelcomeComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'feedback',
    component: FeedbackComponent
  },
  {
    path: 'editFavourite',
    component: EditFavouriteComponent
  },
  {
    path: 'pageNotFound',
    component: NotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: environment.useHashLinks })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
