import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild  } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, } from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { CreateTaskService } from 'src/app/services/create-task.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { UserOption } from 'src/app/models/user.option.model';
import { SelectOptions } from 'src/app/models/select-options.model';
import { TaskForms } from '../task-forms';
import { BaseTaskManagement } from '../base-task-management';
import { TypeCheckHelper } from 'src/app/helpers/typeCheck.helper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {SpeechRecognitionNativeService} from '../../../../../services/speech-recognition-native.service';
import {HttpClient} from '@angular/common/http';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'app-create-task',
  templateUrl: './create-task.component.html',
  styleUrls: ['./create-task.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateTaskComponent extends BaseTaskManagement implements OnInit {

  @ViewChild('inputTitle', {static: false}) inputTitle: ElementRef;
  @ViewChild('inputDesc', {static: false}) inputDesc: ElementRef;

  public createIssueForm: FormGroup;
  public assigneeFormControl = new FormControl();
  public options: UserOption[] = [];
  public filteredOptions: Observable<UserOption[]>;
  public titleShowError = false;
  public descriptionShowError = false;
  public submitted = false;

  constructor(
    public fb: FormBuilder,
    private dialog: MatDialogRef<CreateTaskComponent>,
    private createTaskService: CreateTaskService,
    public speechService: SpeechRecognitionNativeService,
    public typeCheckHelper: TypeCheckHelper,
    public snackBar: MatSnackBar,
    http: HttpClient,
    dialogMat: MatDialog,
    deviceService: DeviceDetectorService

  ) {
   super(speechService, typeCheckHelper, snackBar, http, dialogMat, deviceService);
  }

  ngOnInit() {
    this.createIssueForm = new TaskForms(this.fb).taskForm;
    this.formGroup = this.createIssueForm;

      this.getUsers('4', this.getStorageNodeId()).subscribe((data) => {
      this.options = data.data.userData;
      this.filteredOptions = this.assigneeFormControl.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(assignee => assignee ? this._filter(assignee, this.options) : this.options.slice())
        );
    });
  }

  get formControlValidation() { return this.createIssueForm.controls; }

    ngAfterViewInit() {
        this.inputTitle.nativeElement.addEventListener('blur', () => {
            this.speechService.abort();
        });
        this.inputDesc.nativeElement.addEventListener('blur', () => {
            this.speechService.abort();
        });
    }

  public listenSpeechClick(formElement: string) {
    if (formElement === 'description') {
      this.inputDesc.nativeElement.focus();
      this.listen(this.inputDesc, formElement);
    }
    if (formElement === 'title') {
      this.inputTitle.nativeElement.focus();
      this.listen(this.inputTitle, formElement);
    }
  }

  public async uploadFile(event: Event) {
    await this.readURL(event);
  }

  public selectAssignee(e: MatAutocompleteSelectedEvent) {
    this.createIssueForm.controls['responsible'].setValue(e.option.value);
  }

  public removeAttachmentClick(id: number) {
    this.removeAttachment(id, this.selectedFiles);
  }

  public cancel() {
    this.dialog.close(false);
  }

  public createIssueFormFn() {
    this.submitted = true;
    if (this.createIssueForm.invalid) {
      return;
    }
    this.descriptionShowError = false;
    this.titleShowError = false;
    const task = this.MapTask(this.createIssueForm.value, this.assigneeFormControl);
    this.createTaskService.createTask(task);
    this.dialog.close(true);
  }

  public displayFn(user?: UserOption): string | undefined {
    return user ? user.name : undefined;
  }

}
