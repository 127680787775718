import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OwlModule } from 'ngx-owl-carousel';
import { TaskComponent } from './task/task.component';
import { ActivityComponent } from './activity/activity.component';
import { ViewComponent } from './view/view.component';
import { FilesComponent } from './files/files.component';
import { DashTabsComponent } from './dash-tabs/dash-tabs.component';
import { MaterialModule } from 'src/app/material.module';
import { SharedModule } from '../shared/shared.module';
import { RouterModule, Route } from '@angular/router';
import { CalendarViewComponent } from './task/calendar-view/calendar-view.component';
import { MyResponsibilityComponent } from './task/my-responsibility/my-responsibility.component';
import { TaskListViewComponent } from './task/task-list-view/task-list-view.component';
import { MyReportsComponent } from './task/my-reports/my-reports.component';
import { TaskFileDialogComponent } from './task-file-dialog/task-file-dialog.component';
import { TaskStatusDialogComponent } from './task-status-dialog/task-status-dialog.component';
import { TaskUserDetailDialogComponent } from './task-user-detail-dialog/task-user-detail-dialog.component';
import { PictureAnnotationComponent } from './picture-annotation/picture-annotation.component';
import { PictureCameraComponent } from './picture-annotation/picture-camera/picture-camera.component';
import { CreateTaskComponent } from './task/task-managment/create-task/create-task.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditTaskComponent } from './task/task-managment/edit-task/edit-task.component';
import { ViewTaskComponent } from './task/task-managment/view-task/view-task.component';
import { TaskRemoveDialogComponent } from './task/task_moreOption/task-remove-dialog/task-remove-dialog.component';
import { TaskExportDialogComponent } from './task/task_moreOption/task-export-dialog/task-export-dialog.component';
import { TaskRejectDialogComponent } from './task/task_moreOption/task-reject-dialog/task-reject-dialog.component';
import { TaskArchiveDialogComponent } from './task/task_moreOption/task-archive-dialog/task-archive-dialog.component';
import { ProfileCardComponent } from './activity/profile-card/profile-card.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ImageViewComponent } from './activity/image-view/image-view.component';
import { CloseAlertComponent } from './activity/close-alert/close-alert.component';
import { ChatComponent } from './activity/chat/chat-component';
import { MessageService } from 'src/app/services/message.service';
import { TaskFilterComponent } from './task/task-managment/task-filter/task-filter.component';
import { FileViewerComponent } from './file-viewer/file-viewer.component';
import { BaseTaskManagement } from './task/task-managment/base-task-management';

const routes: Route[] = [
  {
    path: 'dashboard',
    component: DashTabsComponent,
    children: [
      { path: 'calendar-view', component: CalendarViewComponent },
      { path: 'my-responsibilities', component: MyResponsibilityComponent },
      { path: 'list-view', component: TaskListViewComponent },
      { path: 'my-reports', component: MyReportsComponent },
      { path: '', redirectTo: '/dashboard/list-view', pathMatch: 'full' },


    ]
  }
];


@NgModule({
  declarations: [
    TaskComponent, ActivityComponent, ViewComponent,
    FilesComponent, DashTabsComponent, CalendarViewComponent,
    MyResponsibilityComponent, TaskListViewComponent, MyReportsComponent,
    TaskFileDialogComponent, TaskStatusDialogComponent, TaskUserDetailDialogComponent,
    PictureAnnotationComponent, PictureCameraComponent,
    CreateTaskComponent, EditTaskComponent,
    TaskExportDialogComponent, TaskRejectDialogComponent,
    TaskArchiveDialogComponent, ProfileCardComponent,
    ImageViewComponent, CloseAlertComponent,
    ChatComponent, TaskFilterComponent, FileViewerComponent, EditTaskComponent, ViewTaskComponent, TaskRemoveDialogComponent
  ],
  exports: [
    TranslateModule,
  ],
  providers: [TranslateService, MessageService, BaseTaskManagement],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    OwlModule,
    MatSortModule,
    MatTabsModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    ScrollingModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DeviceDetectorModule.forRoot(),
    TranslateModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  entryComponents: [FileViewerComponent]
})
export class DashboardModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/");
}
