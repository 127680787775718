import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-login-type-button',
  templateUrl: './login-type-button.component.html',
  styleUrls: ['./login-type-button.component.scss']
})
export class LoginTypeButtonComponent implements OnInit {
    @Input() icon: String;
    @Input() isActive: Boolean;
    @Output() myEvent = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

    onClick(){
        this.myEvent.emit(this.icon);
    }
}
