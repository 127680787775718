import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-task-remove-dialog',
  templateUrl: './task-remove-dialog.component.html',
  styleUrls: ['./task-remove-dialog.component.scss']
})
export class TaskRemoveDialogComponent implements OnInit {
  public task: any;
  public measureType: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<TaskRemoveDialogComponent>
  ) {
    this.task = data.task;
  }

  ngOnInit() {
    this.measureType = this.getType(this.task.measure_type_id);
  }

  public close() {
    this.dialog.close(false);
  }

  public delete() {
    this.dialog.close('delete');
  }

  private getType(id) {
    switch (id) {
      case '1': return 'Task';
      case '2': return 'Idea';
      case '3': return 'Issue';
    }
  }

}
