import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';

import { TaskService } from 'src/app/services/task.service';

@Component({
  selector: 'app-task-status-dialog',
  templateUrl: './task-status-dialog.component.html',
  styleUrls: ['./task-status-dialog.component.scss']
})
export class TaskStatusDialogComponent implements OnInit {
  public y: any;
  public dataSource = this.taskService;
  public currentDate = Date.now();
  public task: any;
  public status;
  public color;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TaskStatusDialogComponent>, private taskService: TaskService) {
    this.color = this.taskService.getColor(this.data.task.due_dt, this.currentDate);
    this.status = this.data.task.status;
    this.y = Number(this.status);
  }

  ngOnInit() {

  }

  btnfun(x: number) {
    this.y = x;
    this.status = x;
  }
  updateStatus() {
    this.dialogRef.close({ index: this.data.index, status: this.status });
  }

}
