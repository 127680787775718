import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivityItem } from '../models/activityItem.model';

@Injectable({ providedIn: 'root' })
export class ActivityService {
    constructor(private http: HttpClient) { }

    url = './assets/mockData/mockActivities.json';

    getActivities() {
        return this.http.get<ActivityItem[]>(this.url);
    }

    postLike(activityId: number) {
        
    }

}
