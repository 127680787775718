import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { FileFromAPI } from 'src/app/models/file.model';

@Component({
    selector: 'app-carousel-component',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent {
    @ViewChild('slickModal', { read: false, static: true }) slickModal: SlickCarouselComponent;
    @Output() changeImageName = new EventEmitter();
    @Output() prevFile = new EventEmitter();
    @Output() nextFile = new EventEmitter();
    @Input() images: FileFromAPI[];
    @Input() index: number;

    slideConfig = {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '55px',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    centerMode: false,
                    centerPadding: '0px'
                }
            }
        ],
        nextArrow:
            '<button class="right-arrow" name="nextFileEmit"></button>',
        prevArrow:
            '<button class="left-arrow" name="prevFileEmit"></button>'
    };

    nextFileEmit() {
      this.nextFile.emit();
    }

    prevFileEmit() {
      this.prevFile.emit();
    }

    slickInit(e) {
        e.slick.currentSlide = this.index;
    }

    afterChange(e) {
        this.changeImageName.emit(e.slick.currentSlide);
    }

}
