import { Component, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ActivityItem } from 'src/app/models/activityItem.model';
import { ActivityService } from 'src/app/services/activities.service';
import { FormGroup } from '@angular/forms';
import { ProfileCardComponent } from './profile-card/profile-card.component';
import { ProfileService } from 'src/app/services/profile.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CloseAlertComponent } from './close-alert/close-alert.component';
import { UserProfile } from 'src/app/models/userProfile.model';
declare var Pixie: any;

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent implements OnInit {
  public currentDate: Date;
  public thumbStyle: string;
  public activityItems: ActivityItem[];
  public activityDate: FormGroup;
  public activeRow: number;
  public userData: UserProfile;
  public isNowButtonDisplayed: boolean;
  public autoUpdatetimeout: number;
  public isActivitiesAvailable: boolean;
  public isDesktop;
  public imageSrc = null;
  public pixie: any;
  public closeEditor = '';
  public showChat = false;
  public profileIdtest = 12312;
  public chatTitles = [];

  constructor(private dialog: MatDialog, private activityService: ActivityService,
    private profileService: ProfileService, private snackBar: MatSnackBar, private deviceService: DeviceDetectorService) {
    this.activeRow = 0;
    this.isActivitiesAvailable = true;
  }

  ngOnInit() {
    if (this.deviceService.isDesktop()) {
      this.isDesktop = true;
    }
    if (this.deviceService.isTablet() || this.deviceService.isMobile()) {
      this.isDesktop = false;
    }
    this.thumbStyle = 'thumb-up';
    this.currentDate = new Date();
    this.autoUpdate();
    this.isNowButtonDisplayed = false;
    this.autoUpdatetimeout = 60 * 1000;
    this.pixie = new Pixie({
      textureSize: 6000,
      ui: {
        visible: false,
        openImageDialog: false,
        mode: 'overlay',
        allowEditorClose: false,
        nav: {
          replaceDefault: true,
          items: [
            { name: 'resize', icon: 'resize-custom', action: 'resize' },
            { name: 'crop', icon: 'crop-custom', action: 'crop' },
            { name: 'draw', icon: 'pencil-custom', action: 'draw' },
            { name: 'text', icon: 'text-box-custom', action: 'text' },
            { name: 'shapes', icon: 'polygon-custom', action: 'shapes' },
            { name: 'transform', icon: 'transform-custom', action: 'transform' }
          ]
        }
      },
      onClose: () => {
        if (this.closeEditor === 'close') {
          this.closeEditor = '';
          return true;
        }
        if (this.closeEditor === 'no') {
          this.closeEditor = '';
          return false;
        }
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.dialog.open(CloseAlertComponent, dialogConfig);
        dialogConfig.disableClose = false;
        dialogRef.afterClosed().subscribe((data) => {
          if (data === 'save') {
            this.closeEditor = 'close';
            this.pixie.close();
          }
          if (data) {
            this.closeEditor = 'close';
            this.pixie.close();
          }
          if (!data) {
            this.closeEditor = 'no';
          }
        }
        );
        this.closeEditor = '';
        return false;
      }
    });
  }

  public getActivities(date: Date) {
    this.activityService.getActivities().subscribe((data) => {
      this.activityItems = data;
      this.isActivitiesAvailable = true;
    }, error => {
      this.isActivitiesAvailable = false;
      this.showErrorMessage('Can`t receive activities');
    });
  }

  public showProfile(username: string, profileId: number) {
    this.profileIdtest = profileId;
    this.profileService.getProfileById(profileId).subscribe((data) => {
      var tmpData = data;
      tmpData.profileId = profileId;
      tmpData.name = username;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        userData: tmpData
      };
      const dialogRef = this.dialog.open(ProfileCardComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((data) => {
        if (data) {
          this.openChat(tmpData);
        }
      });
    }, error => {
      this.showErrorMessage('Can`t receive user data');
    });
  }

  public autoUpdate() {
    setTimeout(() => {
      if (this.isActivitiesAvailable) {
        this.getActivities(this.currentDate);
      }
      this.autoUpdate();
    }, this.autoUpdatetimeout);
  }

  public onDateSelect(date) {
    this.currentDate = date;
    this.getActivities(date);
  }

  public onLikeClick(item: ActivityItem) {
    item.likes = !item.isLiked ? item.likes + 1 : item.likes - 1;
    item.isLiked = !item.isLiked;
    this.activityService.postLike(item.id);
  }

  public onScroll(event) {
    if (event.target.scrolltop > 10) {
      this.isNowButtonDisplayed = true;
    }
    if (event.target.scrolltop === 0) {
      this.isNowButtonDisplayed = false;
      this.getActivities(this.currentDate);
    }
  }

  public onNowClick() {
    document.getElementsByClassName('virtual-scroll')[0].scrollTo(0, 0);
  }

  private showErrorMessage(message?: string) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 2000,
      });
      return;
    }
    this.snackBar.open('Error occured', '', {
      duration: 2000,
    });
  }

  public onImageUploadClick() {
    document.getElementById('image-input').click();
  }

  public onCameraClick() {
    document.getElementById('camera-input').click();
  }

  public readURL(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.imageSrc = reader.result;
      };
      reader.onload = e => {
        this.imageSrc = reader.result;
        this.showPreview();
      };
    }
    event.target.value = null;
  }

  public showPreview() {
    this.pixie.openEditorWithImage(this.imageSrc);
  }

  public openChat(data: any) {
    this.userData = data;
    this.showChat = true;
    document.getElementById('chat');
  }

  chatsCleared(chatClear) {
    this.showChat = false;
  }

}
