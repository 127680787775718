import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserProfile } from '../models/userProfile.model';

@Injectable({ providedIn: 'root' })
export class ProfileService {
    constructor(private http: HttpClient) { }

    private url = './assets/mockData/mockProfiles.json';

    getProfileById(userId: number) {
        return this.http.get<UserProfile>(this.url);
    }

}
