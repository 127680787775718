import { Component, Output, Input, EventEmitter, OnInit } from '@angular/core';
import FileSaver from 'file-saver';
import { FileFromAPI } from 'src/app/models/file.model';

@Component({
    selector: 'app-video-component',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
    @Output() prevFile = new EventEmitter();
    @Output() nextFile = new EventEmitter();
    @Input() fileIndex: number;
    @Input() maxIndex: number;
    @Input() set file(val: FileFromAPI) {
        if (!val) {
            return;
        }
        this.fileUrl = val.file_path;
        if (this.video) {
            this.video.src = this.fileUrl;
            this.fileName = val.file_name;
        }
    }

    public currentTimeValue = 0;
    public durationValue = 0;
    public currentTime = '';
    public duration = '';
    public progress = 0;
    public video;
    public fileUrl: string;
    public fileName: string;
    public prevVolumeValue = 0;
    public isFullScreen = false;
    public isControlsHidden = false;

    ngOnInit() {
        this.video = document.querySelector('video');
        this.video.volume = 0.5;
        this.video.addEventListener('fullscreenchange', (e) => {
            this.isFullScreen = e.target.webkitDisplayingFullscreen;
        });
    }

    public volumeOnOff() {
        if (this.video.volume !== 0) {
            this.prevVolumeValue = this.video.volume;
            this.video.volume = 0;
        } else {
            this.video.volume = this.prevVolumeValue;
        }
    }

    public mouseEnter(data: boolean) {
        this.isControlsHidden = data;
    }

    public mouseLeave(data: boolean) {
        this.isControlsHidden = data;
    }

    public prevFileEmit() {
        this.prevFile.emit();
    }

    public nextFileEmit() {
        this.nextFile.emit();
    }

    public playPauseVideo() {
        if (!this.video.paused) {
            this.video.pause();
        } else {
            this.video.play();
        }
    }

    public openCloseFullScreen() {
        if (this.video.fullscreenElement) {
            this.isFullScreen = !this.isFullScreen;
        }
        if (this.video.requestFullscreen) {
            this.video.requestFullscreen();
        } else if (this.video.mozRequestFullScreen) {
            this.video.mozRequestFullScreen();
        } else if (this.video.webkitRequestFullscreen) {
            this.video.webkitRequestFullscreen();
        } else if (this.video.msRequestFullscreen) {
            this.video.msRequestFullscreen();
        }
    }

    public volumeChange(e) {
        this.video.volume = e.value;
    }

    public progressUpdate(e) {
        this.durationValue = e.target.duration;
        this.currentTimeValue = e.target.currentTime;
        this.currentTime = this.secondsToHms(Math.floor(e.target.currentTime));
        this.duration = this.secondsToHms(Math.floor(e.target.duration));
        this.progress = (e.target.currentTime * 100) / e.target.duration;
    }

    public slideEnd() {
        this.playPauseVideo();
    }

    public changeVideoProgress(e) {
        if (!this.video.paused) {
            this.video.pause();
        }
        this.video.currentTime = (e.value * this.durationValue) / 100;
    }

    public secondsToHms(time: number) {
        time = Number(time);
        const h = Math.floor(time / 3600);
        const m = Math.floor(time % 3600 / 60);
        const s = Math.floor(time % 3600 % 60);

        const hDisplay = h > 0 ? (h < 10) ? '0' + h + ':' : h + ':' : '00:';
        const mDisplay = m > 0 ? (m < 10) ? '0' + m + ':' : m + ':' : '00:';
        const sDisplay = s > 0 ? (s < 10) ? '0' + s : s : '00';
        return hDisplay.toString() + mDisplay.toString() + sDisplay.toString();
    }

    public save() {
        FileSaver.saveAs(this.fileUrl, this.fileName);
    }

}
