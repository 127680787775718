import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class RoutingStateService {

  private history = [];
  private current = '';
  private popupDialogs: Array<IPopupDialogCloseCallback> = [];

  constructor(
      private router: Router
  ) { }

  public loadRouting(): void {
    this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(({urlAfterRedirects}: NavigationEnd) => {
          this.current = urlAfterRedirects;
          this.history = [...this.history, this.current];
          this.popupDialogs = [];
        });
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/';
  }

  public getCurrentUrl(): string {
    return this.current;
  }

  public pushPopupWindow(callback: IPopupDialogCloseCallback): number {
    this.popupDialogs.push(callback);
    return this.popupDialogs.length;
  }

  public popPopupWindow(): number {
    if (this.popupDialogs.length > 0) {
      this.popupDialogs.pop();
    }
    return this.popupDialogs.length;
  }

  public getPopupWindowCount(): number {
    return this.popupDialogs.length;
  }

  public runPopupWindowCloseCallback(): void {
    if (this.popupDialogs.length > 0) {
      const callback: IPopupDialogCloseCallback = this.popupDialogs[this.popupDialogs.length-1];
      callback();
    }
  }
}

export interface IPopupDialogCloseCallback {
  (): void;
}
