import { Component, OnInit, Inject } from '@angular/core';
import { FormControl} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog} from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CreateTaskService } from 'src/app/services/create-task.service';
import { UserOption } from 'src/app/models/user.option.model';
import { Task } from 'src/app/models/task.model';
import { TaskForms } from '../task-forms';
import { TypeCheckHelper } from 'src/app/helpers/typeCheck.helper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseTaskManagement } from '../base-task-management';
import {EditTaskComponent} from '../edit-task/edit-task.component';
import {SpeechRecognitionNativeService} from '../../../../../services/speech-recognition-native.service';
import {HttpClient} from '@angular/common/http';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'app-view-task',
  templateUrl: './view-task.component.html',
  styleUrls: ['./view-task.component.scss'],
  providers: [TaskForms]
})
export class ViewTaskComponent extends BaseTaskManagement implements OnInit {
  public assigneeFormControl = new FormControl();
  public options: UserOption[] = [];
  public filteredOptions: Observable<UserOption[]>;
  public selectedFiles = [];
  public task = {};

  constructor(public dialogRef: MatDialogRef<ViewTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { data: Task },
    private taskFormCreation: TaskForms,
    private authenticationService: AuthenticationService,
    private createTaskService: CreateTaskService,
    public speechService: SpeechRecognitionNativeService,
    public typeCheckHelper: TypeCheckHelper,
    public snackBar: MatSnackBar,
    private dialog: MatDialog,
    http: HttpClient,
    dialogMat: MatDialog,
    deviceService: DeviceDetectorService

  ) {
    super(speechService, typeCheckHelper, snackBar, http, dialogMat, deviceService);
  }

  ngOnInit() {
    this.task = this.data.data;
    this.selectedFiles = this.data.data.measure_file_data;
    this.selectedFiles.forEach(file => {
      file.fileName = file.file_path.substr(file.file_path.lastIndexOf('/') + 1);
    });
    this.redefineAttachmentId();
    this.assigneeFormControl.setValue({
      id: this.data.data.responsible_user_id,
      name: this.data.data.responsible_name,
    });
    this.getUsers('4', this.getStorageNodeId()).subscribe((data) => {
      this.options = data.data.userData;
      this.filteredOptions = this.assigneeFormControl.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(assignee => {
            return assignee ? this._filter(assignee, this.options) : this.options.slice();
          })
        );
    });
  }

  public cancel() {
    this.dialogRef.close(false);
  }

  public redefineAttachmentId() {
    this.selectedFiles.forEach((file, index) => {
      file.id = index;
    });
  }

  goToEdit() {
    this.cancel();
    this.openEdit(this.task);
  }

  public openEdit(element) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      data: element
    };
    this.dialog.open(EditTaskComponent, dialogConfig);
    dialogConfig.disableClose = true;
  }
}
