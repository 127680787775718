
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user.model';
import {environment} from '../../environments/environment';


@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<User[]>(environment.apiUrl);
  }
  public updateIconsSize(size) {
    const userId = JSON.parse(localStorage.getItem('user_profile')).id;
    const body = new FormData();
    body.append('user_id', userId);
    body.append('size', size);
    body.append('action', 'updateUserIconsSize');
    return this.http.post<any>(environment.apiUrl, body);
  }
}
