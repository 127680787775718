import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';

 
@Component({
  selector: 'app-picture-camera',
  templateUrl: './picture-camera.component.html',
  styleUrls: ['./picture-camera.component.scss']
})


export class PictureCameraComponent implements OnInit {
  @ViewChild("video", { static: false })
  public video: ElementRef;

  @ViewChild("canvas", { static: false })
  public canvas: ElementRef;

  public captures: Array<any>;
  constructor() {
    this.captures = [];
   }

  ngOnInit() {
  

  }
public ngAfterViewInit() {
  if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
          this.video.nativeElement.srcObject = stream;
          this.video.nativeElement.play();
      });
  }
}

public capture() {
  const context = this.canvas.nativeElement.getContext("2d").drawImage(this.video.nativeElement, 0, 0, 640, 480);
  this.captures.push(this.canvas.nativeElement.toDataURL("image"));
}

}
