import { Component, OnInit, ViewEncapsulation } from '@angular/core';

export interface FeedbackOptions {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss'],
  encapsulation: ViewEncapsulation.None, //added for cdk-panel css overwite default one.
})

export class FeedbackFormComponent implements OnInit {
  public feedbacktrue: Boolean = false;
  public feedbackOptions: FeedbackOptions[] = [
    { value: 'general-review', viewValue: 'General review' },
    { value: 'ideas-or-feature-requests', viewValue: 'Ideas or feature requests' },
    { value: 'complains-or-system-bugs', viewValue: 'Complains or system bugs' }

  ];
  constructor() { }

  ngOnInit() {

  }

  radioselect() {
    this.feedbacktrue = true;
  }

}
