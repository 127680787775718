import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
// import { NgxDocViewerComponent } from 'ngx-doc-viewer';
import { FileFromAPI } from 'src/app/models/file.model';
import { PdfViewerComponent} from 'ng2-pdf-viewer';

@Component({
    selector: 'app-docs-component',
    templateUrl: 'docs.component.html',
    styleUrls: ['docs.component.scss']
})

export class DocsComponent implements AfterViewInit {
    @Output() prevFile = new EventEmitter();
    @Output() nextFile = new EventEmitter();
    @Input() fileIndex: number;
    @Input() maxIndex: number;
    @Input() file: FileFromAPI;

    // @ViewChild(NgxDocViewerComponent, { static: false }) viewer: NgxDocViewerComponent;
    // @ts-ignore
    @ViewChild(PdfViewerComponent) private pdfComponent: PdfViewerComponent;
    public isLoading = true;

    constructor() { }

    ngAfterViewInit(): void {
        this.pdfComponent.afterLoadComplete.subscribe(() => {
            this.isLoading = false;
        });
    }

    public prevFileEmit() {
        this.prevFile.emit();
        this.isLoading = true;
    }

    public nextFileEmit() {
        this.nextFile.emit();
        this.isLoading = true;
    }
}
