import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {FileFromAPI} from '../models/file.model';
@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(private http: HttpClient) { }

  getUserFile(level: string, company_id: string, user_id: string, node_id: string) {
    const body = new FormData();
    body.append('level', level);
    body.append('company_id', company_id);
    body.append('user_id', user_id);
    body.append('node_id', node_id);
    body.append('action', 'getFileListByApp');
    return this.http.post<any>(environment.apiUrl, body)
      .pipe(map(userFile => {
        return userFile;
      }));
  }

  public storeFile(file: FileFromAPI)  {
    const body = new FormData();
    Object.keys(file).forEach(function (item) {
      body.append(item, file[item]);
    });
    body.append('action', 'insertFileUpload');
    return this.http.post<any>(environment.apiIndexUrl, body);
  }
}
