import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { NodeDialogueTaskComponent } from './node-dialog-task/node-dialog-task.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NodesService } from 'src/app/services/nodes.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  constructor(private dialog: MatDialog, private translate: TranslateService,
    private nodesService: NodesService , private router: Router) {
    translate.setDefaultLang('de');
  }
  public isFeedback = false;
  public url = ' ';
  public show: Boolean = false;
  public selectedList = '';
  public dialogConfig = new MatDialogConfig();
  public dialogRefA: MatDialogRef<NodeDialogueTaskComponent>;
  public settingShow: Boolean = false;
  public factory: any[];
  public nodeData: any[];
  public data: any;
  ngOnInit() {
    this.nodeData = NodesService.onGettingNodes();
    this.isFeedback = this.router.url === '/feedback';
    this.setNode(localStorage.getItem('currentNode'), localStorage.getItem('nodeId'));
  }
  public showOn() {
    this.show = !this.show;
  }

  public setNode(nodeName, nodeId) {
    this.selectedList = nodeName;
    localStorage.setItem('currentNode', nodeName);
    localStorage.setItem('nodeId', nodeId);
  }
  settingShowFun() {
    this.settingShow = !this.settingShow;
  }

  public nodeDisplay() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
    };
    this.dialog.open(NodeDialogueTaskComponent, dialogConfig);
    this.dialogConfig.disableClose = false;
  }

  public hideNotification() {
    this.show = false;
  }
  hideSidebarA() {
    this.settingShow = false;
  }
}
