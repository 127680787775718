import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-favourite',
  templateUrl: './edit-favourite.component.html',
  styleUrls: ['./edit-favourite.component.scss']
})
export class EditFavouriteComponent implements OnInit {
  temolate_list: any[] = ['measurement 552', 'idea 007', 'issue25','task template','checklist 01','something'];
  constructor() { }

  ngOnInit() {
  }

}
