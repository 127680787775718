import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ImprintDialogueComponent } from '../imprint-dialog/imprint-dialog.component';
import { SettingPrivacyDialogueComponent } from '../setting-privacy-dialog/setting-privacy-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'app-header-setting',
  templateUrl: './header-setting.component.html',
  styleUrls: ['./header-setting.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderSettingComponent implements OnInit {

  public user_profile = JSON.parse(localStorage.getItem('user_profile'));

  constructor(private _router: Router, private dialog: MatDialog,  private translate: TranslateService
  ) {
  }

  ngOnInit() {
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  currentLanguage(): string {
    return this.translate.currentLang;
  }

  logout() {
    localStorage.removeItem('showArchivedTasks');
    this._router.navigate(['/login']);
  }

  feedbackSetting() {
    this._router.navigate(['/feedback']);
  }
  policySettingDisplay() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
    }
    this.dialog.open(SettingPrivacyDialogueComponent, dialogConfig);
    dialogConfig.disableClose = true;

  }

  imprintSettingDisplay() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
    }
    this.dialog.open(ImprintDialogueComponent, dialogConfig);
    dialogConfig.disableClose = true;

  }
}
