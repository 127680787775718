import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
declare var Pixie: any

@Component({
    selector: 'app-image-view',
    templateUrl: './image-view.component.html',
    styleUrls: ['./image-view.component.scss']

})
export class ImageViewComponent implements OnInit {

    public imageSrc: string;
    public canvas: any;
    public lastSelectedPicture = null;
    public isInsertingCropRectangle = false;
    public target = null;
    public mask = null;
    public crop_rect;
    public isDown;
    public origX;
    public origY;
    public done = false;
    public colorCode;
    public selectMode = true;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
        this.imageSrc = data.imageSrc;
    }

    ngOnInit() {
        const pixie = new Pixie({
            ui: {
              // mode: 'overlay',
              nav: {
                //replaceDefault: true,
                items: [
                  { name: 'crop', icon: 'crop-custom', action: 'crop' },
                  { name: 'draw', icon: 'pencil-custom', action: 'draw' },
                ]
              }
            },
            image: this.imageSrc,
            onLoad: function () {
            }
          });
    }

}
