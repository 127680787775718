import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {CordovaService} from '../../../services/cordova.service';

@Component({
  selector: 'app-login-nfc',
  templateUrl: './login-nfc.component.html',
  styleUrls: ['./login-nfc.component.scss']
})
export class LoginNfcComponent implements OnInit, OnDestroy {

    @Input() nfc: String;

  constructor(
      private cordova: CordovaService
  ) { }

    ngOnInit() {
        if (this.cordova.isNfc) {
            this.cordova.registerNfcStatusCallback(this.nfcStatus);
            this.cordova.registerNfcScan(this.nfcValue);
            this.cordova.setNfcStatusCheck(true);
        }

        this.nfc = 'STAT: ' + new Date();
    }

    ngOnDestroy() {
        if (this.cordova.isNfc) {
            this.cordova.setNfcStatusCheck(false);
            this.cordova.unregisterNfcScan(this.nfcValue);
            this.cordova.unregisterNfcStatusCallback(this.nfcStatus);
        }
    }

    public nfcStatus = (status: any) => {
        this.nfc = JSON.stringify(status) + ' = ' + new Date();
    }

    public nfcValue = (value: any) => {
        alert('value: ' + JSON.stringify(value));
    }

}
