import { Component, OnInit } from '@angular/core';
import { BottomrunninglinksComponent } from './bottomrunninglinks/bottomrunninglinks.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FilesService } from 'src/app/services/files.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-bottom',
  templateUrl: './bottom.component.html',
  styleUrls: ['./bottom.component.scss']
})
export class BottomComponent implements OnInit {
  public collapseA = false;
  public inlinExpand: boolean = false;
  public tickeActive: boolean = false;
  public isChecked: boolean = false;
  public checkedData: [];
  public myDate: Date;
  public displayMenu: boolean = false;
  public dialogRef: MatDialogRef<BottomrunninglinksComponent>;
  public playBtn: boolean = true;
  public hasData: boolean = true;
  public dialogConfig:MatDialogConfig;
  public urgentInfo: any;
  public infoId: Number;
  public showInlineNoticeCont: boolean = false;
  public i: number;
  constructor(private dialog: MatDialog,
    private filesService: FilesService,
    private translate: TranslateService) {
    translate.setDefaultLang('de');
  }
  ngOnInit() {
    this.dialogConfig = new MatDialogConfig();
    this.utcTime();
    let user_profile = JSON.parse(localStorage.getItem('user_profile'));
    this.filesService.getUserFile('4', user_profile.company_id, user_profile.id, '5').subscribe(res => {
      this.urgentInfo = res.data.urgentInfoData;
    });
  }
  useLanguage(language: string) {
    this.translate.use(language);
  }
  utcTime(): void {
    setInterval(() => {
      this.myDate = new Date();
    }, 1000);
  }
  public bottomLinks(urgentInfo: any, i: number,
    bg_color: any, info: any, start_dt: any, last_change_dt: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      urgentInfo: urgentInfo[i],
      bg_color: bg_color,
      info: info,
      start_dt: start_dt,
      last_change_dt: last_change_dt,
      index: i
    };
    this.dialogRef = this.dialog.open(BottomrunninglinksComponent, dialogConfig);
  }
  togglePlayBtn() {
    this.playBtn = !this.playBtn;
    this.tickeActive = !this.tickeActive;
  }
  displayMenuFun() {
    this.displayMenu = !this.displayMenu;
  }
  showInlineNoticeContFun(i: number) {
    this.infoId = i;
    this.inlinExpand = !this.inlinExpand;
  }
  onChkChange(e, obj, i) {
    if (e.checked === true) {
      this.infoId = i;
    }
    if (e.checked === false) {
      this.infoId = -1;
    }
  }

}
