import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingStateService } from '../../../services/routing-state.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { first } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login-email',
  templateUrl: './login-email.component.html',
  styleUrls: ['./login-email.component.scss']
})

export class LoginEmailComponent implements OnInit {
  public logForm: FormGroup;
  public dialogConfig = new MatDialogConfig();
  public showError = false;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  public error = '';

  public dialogRef: MatDialogRef<ForgotPasswordComponent>;

  constructor(private fb: FormBuilder,
    private dialog: MatDialog
    , private routingState: RoutingStateService,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private cookies: CookieService
  ) {
    this.dialogConfig.data = {};
  }


  ngOnInit() {
    this.logForm = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      rememberMe: [''],
    });
    
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'dashboard/list-view';
    if (this.cookies.get('rememberData') !== '') {
      const rememberData = JSON.parse(this.cookies.get('rememberData'));
      this.logForm.setValue({
        username: rememberData.username,
        password: rememberData.password,
        rememberMe: true,
      });
    }
  }

  public forgotPassword() {
    this.dialogRef = this.dialog.open(
      ForgotPasswordComponent,
      this.dialogConfig
    );
    this.dialogRef.afterOpened().subscribe(result => {
      this.routingState.pushPopupWindow(this.closeForgotPasswordComponent);
    });
    this.dialogRef.afterClosed().subscribe(result => {
      this.routingState.popPopupWindow();
    });
    this.dialogRef.disableClose = true;
  }
  public onSubmit() {
    this.cookies.set('username', 'this.logForm.value.username');
    this.submitted = true;
    
    if (this.logForm.invalid) {
      return;
    }
    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        userData => {
          if (this.logForm.value.rememberMe) {
            this.cookies.set('rememberData', JSON.stringify(
              {
                'username': this.logForm.value.username,
                'password': this.logForm.value.password
              }));
          }
          if (userData.success === 'yes') {
            const profile = userData.data.profileData;
            const nodes = userData.data.nodesData;
            localStorage.setItem('user_profile', JSON.stringify(profile));
            localStorage.setItem('nodes_data', JSON.stringify(nodes));
            this.cookies.set('username', JSON.stringify(this.logForm.value.username));
            this.router.navigate([this.returnUrl]);
          } else {
            this.showError = true;
            this.error = 'Your email and/or password do not match.';

          }
        },
        error => {
          this.showError = true;
          this.error = 'Your email and/or password do not match.';
        }
      );
  }
  get f() { return this.logForm.controls; }

  public closeForgotPasswordComponent = () => {
    this.dialogRef.close();
  }
}
