import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ITreeData} from '../models/nodeTree.model';

@Injectable({
  providedIn: 'root'
})
export class NodesService {
  public nodeTreeApi = './assets/taskJsonFile/nodeTree.json';
  constructor(private http: HttpClient) { }

  static onGettingNodes() {
    return JSON.parse(localStorage.getItem('nodes_data'));
  }

  getNodeTree(id: number) {
    const body = 1;
    return this.http.get<ITreeData>(this.nodeTreeApi);
  }
}
