import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateTaskComponent } from '../create-task/create-task.component';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { SelectOptions } from 'src/app/models/select-options.model';
import { BaseTaskManagement } from '../base-task-management';

@Component({
  selector: 'app-task-filter',
  templateUrl: './task-filter.component.html',
  styleUrls: ['./task-filter.component.scss']
})

export class TaskFilterComponent implements OnInit {
  public tasks: any;
  public usersReporters: any;
  public usersAssignees: any;
  public user_profile: any;
  public filteredReporters: any;
  public assigneeFormControl = new FormControl();
  public reporterFormControl = new FormControl();
  public filteredAssignees: any;
  public filterForm: FormGroup;
  public test = true;
  public baseTypeOptions: SelectOptions[] = [
    { value: '1', viewValue: 'Task', icon: '/assets/images/create_task_images/task.svg' },
    { value: '2', viewValue: 'Idea', icon: '/assets/images/create_task_images/idea.svg' },
    { value: '3', viewValue: 'Issue', icon: '/assets/images/create_task_images/issue.svg' }
  ];

  private filterData: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialog: MatDialogRef<TaskFilterComponent>
  ) {
    this.tasks = data.tasks.measuresData;
    this.filterData = data.filterData;
    this.usersReporters = data.tasks.userData;
    this.usersAssignees = data.tasks.userData;
    this.user_profile = JSON.parse(localStorage.getItem('user_profile'));
    this.usersAssignees.push({
      id: this.user_profile.id,
      name: 'to me'
    });
    this.usersReporters.push({
      id: this.user_profile.id,
      name: 'to me'
    });
  }

  ngOnInit() {
    this.filterForm = this.fb.group({
      type: ['', Validators.required],
      keyWords: [''],
      reporter: [''],
      assignee: [''],
      creationDate: [''],
      creationTime: [''],
      dueTime: [''],
      dueDate: [''],
      enableArchivedTasks: false
    });

    if (this.filterData) {
      this.filterForm.patchValue({
        dueDate: this.filterData.dueDate,
        dueTime: this.filterData.dueTime,
        creationDate: this.filterData.creationDate,
        creationTime: this.filterData.creationTime,
        keyWords: this.filterData.keyWords,
        type: this.filterData.type,
        enableArchivedTasks: this.filterData.enableArchivedTasks
      });
      this.assigneeFormControl.setValue(this.filterData.assignee);
      this.reporterFormControl.setValue(this.filterData.reporter);
    }


    this.filteredReporters = this.reporterFormControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(reporter => reporter ? this._filterReporters(reporter) : this.usersReporters.slice())
      );

    this.filteredAssignees = this.assigneeFormControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(assignee => assignee ? this._filterAssignees(assignee) : this.usersAssignees.slice())
      );
  }

  public applyFilter() {
    if (this.assigneeFormControl.value && this.assigneeFormControl.value.name) {
      this.filterForm.value.assignee = this.assigneeFormControl.value.name;
    } else if (this.filterData && this.filterData.assignee) {
      this.filterForm.value.assignee = this.filterData.assignee;
    }
    if (this.reporterFormControl.value && this.reporterFormControl.value.name) {
      this.filterForm.value.reporter = this.reporterFormControl.value.name;
    } else if (this.filterData && this.filterData.reporter) {
      this.filterForm.value.reporter = this.filterData.reporter;
    }
    if (this.assigneeFormControl.value === 'to me') {
      this.filterForm.value.assignee = this.user_profile.name;
    }
    if (this.reporterFormControl.value === 'to me') {
      this.filterForm.value.reporter = this.user_profile.name;
    }
    this.dialog.close(this.filterForm.value);
  }

  displayFnReporter(user?: any): string | undefined {
    if (!user) {
      return undefined;
    }
    if (user.name) {
      return user.name;
    }
    return user;
  }

  displayFnAssignee(user?: any): string | undefined {
    if (!user) {
      return undefined;
    }
    if (user.name) {
      return user.name;
    }
    return user;
  }

  public close() {
    this.dialog.close(false);
  }

  public clearFilters() {
    this.dialog.close('clear');
  }

  private _filterAssignees(name: string) {
    if (name === '') {
      return;
    }
    return this.usersAssignees.filter(option => option.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  private _filterReporters(name: string) {
    if (name === '') {
      return;
    }
    return this.usersReporters.filter(optionRep => optionRep.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }
}
