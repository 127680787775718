import { HttpClient } from '@angular/common/http';
import { Injectable, Input, OnChanges } from '@angular/core';
import { map } from 'rxjs/operators';
import { IUserDetail } from '../models/userDetails.model';
import { IUserFileDetail } from '../models/userFileDetail.model';
import { Task } from '../models/task.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(private http: HttpClient) { }

  public getMeasures(level: string, company_id: string, user_id: string, node_id: string) {
    const body = new FormData();
    body.append('level', level);
    body.append('company_id', company_id);
    body.append('user_id', user_id);
    body.append('node_id', node_id);
    body.append('action', 'getAllMeasuresByApp');
    return this.http.post<any>(environment.apiUrl, body)
      .pipe(map(user => {
        return user;
      }));
  }

  public getColor(date, currentDate) {
    let color = '';
    const date1 = new Date(currentDate);
    const date2 = new Date(date);
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > 2) {
      color = 'green';
    } else if (diffDays <= 2 && diffDays > 0) {
      color = 'yellow';
    } else {
      color = 'red';
    }
    return color;
  }

  public getUserDetail(id: number) {
    const body = 1;
    return null;
  }

  public getUserCalendarView() {
    const body = 1;
    return null;
  }

  public rejectTask(task: Task) {
    return;
    return this.http.post(environment.apiUrl, task.id);
  }

  public removeTask(task: Task)  {
    const body = new FormData();
    body.append('measure_id', task.id);
    body.append('action', 'deleteSingleMeasure');
    return this.http.post<any>(environment.apiUrl, body);
  }

  public archiveTask(task: Task)  {
    const body = new FormData();
    let archived = null;
    if (task['is_archived'] == 1) {
      archived = 0;
    } else if (task['is_archived'] == 0) {
      archived = 1;
    }
    body.append('measure_id', task.id);
    body.append('is_archived', archived);
    body.append('action', 'archiveMeasure');
    return this.http.post<any>(environment.apiUrl, body);
  }

}
