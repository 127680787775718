import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-advanceoverlay',
  templateUrl: './advanceoverlay.component.html',
  styleUrls: ['./advanceoverlay.component.scss']
})
export class AdvanceoverlayComponent implements OnInit {
  public advForm: FormGroup;
  constructor(private fb: FormBuilder, private _router: Router,private translate:TranslateService) { }
  ngOnInit() {
    this.advForm = this.newForm();
  }
  newForm(): FormGroup {
    return this.fb.group({
      advUrl: ['', [Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],

    });
  }
  onSubmit() {
    alert('Thank you ,Our team will contact you soon');
    this._router.navigate(['/']);
  }
}
