import {Component, DoCheck, OnInit} from '@angular/core';
import { FilesService } from 'src/app/services/files.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {BaseTaskManagement} from '../task/task-managment/base-task-management';
import {EventService} from '../../../services/event.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit, DoCheck {
  public iconsClassName: string = null;
  public cardTitleClassName: string = null;
  public fileTask: any;
  public data: any;
  public dataSource: any;
  public file_path: any;
  public videoPath = false;
  public id: any;
  public url: SafeResourceUrl;
  private nodeId;
  private userProfile = JSON.parse(localStorage.getItem('user_profile'));
  public showViews = true;
  public showNotFoundPage = false;
  urlSafe: SafeResourceUrl;
  constructor(private filesService: FilesService,
              public sanitizer: DomSanitizer,
              private baseTaskManagement: BaseTaskManagement,
              private eventService: EventService
  ) { }

  ngOnInit() {
    this.eventService.sliderChangeEvent
      .subscribe((data: string) => {
        this.setIconsSize(JSON.parse(localStorage.getItem('user_profile')));
      });
    this.setIconsSize(this.userProfile);
    this.nodeId = this.baseTaskManagement.getStorageNodeId();
    this.getAllViews();
  }

  ngDoCheck() {
    const change = this.baseTaskManagement.getStorageNodeId();
    if (change !== this.nodeId) {
      this.setNodeId(change);
      this.getAllViews();
    }
  }

  setIconsSize (user) {
    if (user.icons_size) {
      if (user.icons_size === 'Small') {
        this.iconsClassName = 'icon-small';
        this.cardTitleClassName = 'viewCardTitle';
      } else if (user.icons_size === 'Medium') {
        this.iconsClassName = 'icon-medium';
        this.cardTitleClassName = 'view-card-title-medium-padding viewCardTitle';
      } else if (user.icons_size === 'Big') {
        this.cardTitleClassName = 'view-card-title-big-padding viewCardTitle';
        this.iconsClassName = 'icon-big';
      }
    }
  }

  private setNodeId(nodeId) {
    this.nodeId = nodeId;
  }


  private getAllViews() {
    this.filesService.getUserFile('4', this.userProfile.company_id, this.userProfile.id, this.nodeId).subscribe(res => {
      this.dataSource = res.data.systemViewData;
      this.fileTask = this.sanitizer.bypassSecurityTrustUrl(this.dataSource.file_path);
    });
  }

  checkExt(link) {
    const linkArr = link.split('.');
    const filename = linkArr[linkArr.length, 0];
    const fileNameArr = filename.split(':');
    const fileExt = fileNameArr[fileNameArr.length, 0].toLowerCase();
    let type = 'Invalid';
    if (fileExt === 'http' || fileExt === 'https') {
      type = 'Http';
    } else if (fileExt === 'www') {
      type = 'WWW';
    } else if (fileExt !== 'http' || fileExt === 'https' && fileExt !== 'www') {
      type = 'Other';
    }
    return type;
  }

  backToViews() {
    if (this.showViews === false) {
      this.showViews = true;
    }
    if (this.showNotFoundPage) {
      this.showNotFoundPage = false;
    }
  }

  getPage(path) {
    this.showViews = false;
    if (path === '/pageNotFound') {
      this.showNotFoundPage = true;
    } else {
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(path);
    }
  }
}
