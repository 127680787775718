import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor() { }

  @Output() sliderChangeEvent = new EventEmitter<string>();

  sliderChanged(msg: string) {
    this.sliderChangeEvent.emit(msg);
  }
}
