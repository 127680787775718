import {Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, } from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CreateTaskService } from 'src/app/services/create-task.service';
import { TaskUpdate } from 'src/app/models/taskUpdate.model';
import { UserOption } from 'src/app/models/user.option.model';
import { Task } from 'src/app/models/task.model';
import { TaskForms } from '../task-forms';
import { TypeCheckHelper } from 'src/app/helpers/typeCheck.helper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseTaskManagement } from '../base-task-management';
import { SelectOptions } from 'src/app/models/select-options.model';
import {SpeechRecognitionNativeService} from '../../../../../services/speech-recognition-native.service';
import {HttpClient} from '@angular/common/http';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'app-edit-task',
  templateUrl: './edit-task.component.html',
  styleUrls: ['./edit-task.component.scss'],
  providers: [TaskForms]
})
export class EditTaskComponent extends BaseTaskManagement implements OnInit {

  @ViewChild('inputTitle', {static: false}) inputTitle: ElementRef;
  @ViewChild('inputDesc', {static: false}) inputDesc: ElementRef;

  public assigneeFormControl = new FormControl();
  public options: UserOption[] = [];
  public filteredOptions: Observable<UserOption[]>;
  public editTaskForm: FormGroup;
  public date = Date;
  public fileUrl = this.authenticationService.fileUrl;
  public submitted = false;
  public selectedFiles = [];
  public titleShowError = false;
  public descriptionShowError = false;
  public typeOptions: SelectOptions[] = this.baseTypeOptions;
  public frequencyOptions: SelectOptions[] = this.baseFrequencyOptions;

  constructor(public dialogRef: MatDialogRef<EditTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { data: Task },
    private taskFormCreation: TaskForms,
    private authenticationService: AuthenticationService,
    private createTaskService: CreateTaskService,
    public speechService: SpeechRecognitionNativeService,
    public typeCheckHelper: TypeCheckHelper,
    public snackBar: MatSnackBar,
    http: HttpClient,
    dialogMat: MatDialog,
    deviceService: DeviceDetectorService

  ) {
    super(speechService, typeCheckHelper, snackBar, http, dialogMat, deviceService);
  }

  ngOnInit() {
    this.editTaskForm = this.taskFormCreation.taskForm;
    this.formGroup = this.editTaskForm;

    this.editTaskForm.patchValue({
      title: this.data.data.measure_des,
      description: this.data.data.remark,
      date: this.data.data.due_dt.split(' ')[0],
      time: this.data.data.due_dt.split(' ')[1].split(':')[0] +
        ':' + this.data.data.due_dt.split(' ')[1].split(':')[1],
      responsible: this.data.data.responsible_name,
      enableChat: true,
      frequency: this.data.data.interval,
      type: this.data.data.measure_type_id
    });

    this.selectedFiles = this.data.data.measure_file_data;
    this.selectedFiles.forEach(file => {
      file.fileName = file.file_path.substr(file.file_path.lastIndexOf('/') + 1);
    });
    this.redefineAttachmentId();
    this.assigneeFormControl.setValue({
      id: this.data.data.responsible_user_id,
      name: this.data.data.responsible_name,
    });

    this.editTaskForm.controls['responsible'].setValue(this.data.data.responsible_name);

    this.getUsers('4', this.getStorageNodeId()).subscribe((data) => {
      this.options = data.data.userData;

      this.filteredOptions = this.assigneeFormControl.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(assignee => {
            return assignee ? this._filter(assignee, this.options) : this.options.slice();
          })
        );
    });

  }

  get formControlsValidation() { return this.editTaskForm.controls; }

    ngAfterViewInit() {
        this.inputTitle.nativeElement.addEventListener('blur', () => {
            this.speechService.abort();
        });
        this.inputDesc.nativeElement.addEventListener('blur', () => {
            this.speechService.abort();
        });
    }

  public listenSpeechClick(formElement: string) {
      if (formElement === 'description') {
          this.inputDesc.nativeElement.focus();
          this.listen(this.inputDesc, formElement);
      }
      if (formElement === 'title') {
          this.inputTitle.nativeElement.focus();
          this.listen(this.inputTitle, formElement);
      }
  }

  public selectAssignee(e) {
    this.editTaskForm.controls['responsible'].setValue(e.option.value);
  }

  public cancel() {
    this.dialogRef.close(false);
  }

  public async editIssueFormFn() {
    this.submitted = true;
    if (this.editTaskForm.invalid) {
      return;
    }
    this.descriptionShowError = false;
    this.titleShowError = false;
    const task = await this.createTaskModel();
    task.measures_id = parseInt(this.data.data.id, 10);
    this.createTaskService.updateTask(task).subscribe((data) => {
      this.dialogRef.close(true);
    });
  }

  public displayFn(user?: UserOption): UserOption | string | undefined {
    if (user.name) {
      return user.name;
    }
    return user;
  }

  public async uploadFile(event) {
    this.readURL(event);
  }

  public removeAttachmentClick(id) {
    this.removeAttachment(id, this.selectedFiles);
  }

  public redefineAttachmentId() {
    this.selectedFiles.forEach((file, index) => {
      file.id = index;
    });
  }

  private async createTaskModel() {
    const task: TaskUpdate = {
      measures_id: +this.data.data.id,
      ...await this.MapTask(this.editTaskForm.value, this.assigneeFormControl)
    };
    let id: number;
    if (this.assigneeFormControl.value.id) {
      id = this.assigneeFormControl.value.id;
    } else {
      id = +this.data.data.responsible_user_id;
    }
    task.responsible_user_id = id;
    return task;
  }

}
