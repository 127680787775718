import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feedback-header',
  templateUrl: './feedback-header.component.html',
  styleUrls: ['./feedback-header.component.scss']
})
export class FeedbackHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
