import {Component, DoCheck, OnInit} from '@angular/core';
import { FilesService } from 'src/app/services/files.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { FileFromAPI } from 'src/app/models/file.model';
import { BaseTaskManagement } from '../task/task-managment/base-task-management';
import {EventService} from '../../../services/event.service';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})

export class FilesComponent implements OnInit, DoCheck {
  public iconsClassName: string = null;
  public dataSource: FileFromAPI[];
  public pageContent: FileFromAPI[];
  public pageSize = 25;
  public pageCount: number[];
  public maximumSize: number;
  public currentPage: number;
  private _allFiles: FileFromAPI[];
  public audioFilter: Boolean = true;
  public imageFilter: Boolean = true;
  public videoFilter: Boolean = true;
  public pdfFilter: Boolean = true;
  public filterModal = false;
  public filteredData = [];
  public isFileViewerOpen = false;
  public fileName = '';
  public droppedFiles: NgxFileDropEntry[] = [];
  public highlightLastFile = false;
  public showUploadLoader = false;
  public droppedFileToPreview;
  public selectedFile: FileFromAPI;
  public selectedFileIndex: number;
  public images: FileFromAPI[] = [];
  public docs: FileFromAPI[] = [];
  public videos: FileFromAPI[] = [];
  public audios: FileFromAPI[] = [];
  public currentFileTypeIndex = 0;
  public selectedFileType = '';
  public test: any[] = [];
  private source: FileFromAPI[];
  private nodeId;
  private userProfile = JSON.parse(localStorage.getItem('user_profile'));

  constructor(private filesService: FilesService,
              public sanitizer: DomSanitizer,
              private route: Router,
              private baseTaskManagement: BaseTaskManagement,
              private eventService: EventService
  ) { }

  ngOnInit() {
    this.nodeId = this.baseTaskManagement.getStorageNodeId();
    this.getAllFiles();
    this.eventService.sliderChangeEvent
      .subscribe((data: string) => {
        this.setIconsSize(JSON.parse(localStorage.getItem('user_profile')));
      });
    this.setIconsSize(this.userProfile);
  }

  ngDoCheck() {
    const change = this.baseTaskManagement.getStorageNodeId();
    if (change !== this.nodeId) {
      this.setNodeId(change);
      this.getAllFiles();
    }
  }

  setIconsSize (user) {
    if (user.icons_size) {
      if (user.icons_size === 'Small') {
        this.iconsClassName = 'icon-small';
      } else if (user.icons_size === 'Medium') {
        this.iconsClassName = 'icon-medium';
      } else if (user.icons_size === 'Big') {
        this.iconsClassName = 'icon-big';
      }
    }
  }

  private setNodeId(nodeId) {
    this.nodeId = nodeId;
  }
  private getAllFiles() {
    this.filesService.getUserFile('4', this.userProfile.company_id, this.userProfile.id, this.nodeId).subscribe(res => {
      const tmp = res.data.fileUploadData;
      this._allFiles = tmp;
      this.source = tmp;
      this.maximumSize = res.data.fileUploadData.length;
      this.currentPage = 1;
      this.sortFilesByType(this._allFiles);
    });
  }

  private sortFilesByType(Files: FileFromAPI[]) {
    this.maximumSize = Files.length;
    this.dataSource = this.initPageContent(Files);

    Files.forEach(file => {
      const type = this.checkExt(file.file_path);
      if (type === 'Pdf') {
        this.docs.push(file);
      }
      if (type === 'Video') {
        this.videos.push(file);
      }
      if (type === 'Image') {
        this.images.push(file);
      }
      if (type === 'Audio') {
        this.audios.push(file);
      }
    });
  }

  public initPageContent(elements) {
    const amountOfPages = Math.ceil(elements.length / this.pageSize);
    this.pageCount = Array(amountOfPages).fill(0).map((x, i) => i);
    return this.pageContent = elements.slice(0, this.pageSize);
  }

  public changePage(page: number) {
    if (page < 1 || page > this.pageCount.length || this.pageCount.length === 1) {
      return;
    }

    this.currentPage = page;
    page = page - 1;
    this.pageContent = this.test.slice((page * this.pageSize), ((page * this.pageSize) + this.pageSize));
    this.dataSource = this.pageContent;
  }

  public goToFirstPage() {
    this.changePage(1);
  }

  public goToLastPage() {
    this.changePage(this.pageCount.length);
  }

  public openFilter() {
    this.filterModal = !this.filterModal;
  }

  public applyFilter(event, value) {

    this.dataSource = this.source;

    this[value] = event.target.checked;
    if (!this.videoFilter) {
      this.dataSource = this.dataSource.filter(
        x => !this.videos.some(
          v => v === x
        )
      );
    }
    if (!this.imageFilter) {
      this.dataSource = this.dataSource.filter(
        x => !this.images.some(
          v => v === x
        )
      );
    }
    if (!this.audioFilter) {
      this.dataSource = this.dataSource.filter(
        x => !this.audios.some(
          v => v === x
        )
      );
    }
    if (!this.pdfFilter) {
      this.dataSource = this.dataSource.filter(
        x => !this.docs.some(
          v => v === x
        )
      );
    }
    this.initPageContent(this.dataSource);
  }

  public checkExt(link) {
    if (!link) {
      return;
    }
    const linkArr = link.split('/');
    const filename = linkArr[linkArr.length - 1];
    const fileNameArr = filename.split('.');
    const fileExt = fileNameArr[fileNameArr.length - 1].toLowerCase();
    let type = 'Invalid';

    if (fileExt === 'jpg' || fileExt === 'jpeg' || fileExt === 'png' || fileExt === 'gif' || fileExt === 'svg') {
      type = 'Image';
    }
    if (fileExt === 'mp4' || fileExt === 'avi' || fileExt === 'mov' ||
      fileExt === 'mpg' || fileExt === 'm4v' || fileExt === 'swf' || fileExt === 'wmv' || fileExt === 'webm') {
      type = 'Video';
    }
    if (fileExt === 'pdf') {
      type = 'Pdf';
    }
    if (fileExt === 'mp3' || fileExt === 'wav' || fileExt === 'm4a') {
      type = 'Audio';
    }
    return type;
  }

  public clearFilter() {
    this.dataSource = this.source;
  }

  public nextFilesType() {
    if (this.currentFileTypeIndex < 3) {
      this.currentFileTypeIndex++;
    } else {
      this.currentFileTypeIndex = 0;
    }

    this.selectViewerData();
  }

  public prevFilesType() {
    if (this.currentFileTypeIndex > 0) {
      this.currentFileTypeIndex--;
    } else {
      this.currentFileTypeIndex = 3;
    }

    this.selectViewerData();
  }

  public openFileViewer(file) {
    this.filteredData = [];
    this.fileName = file.file_name;
    this.isFileViewerOpen = true;
    this.selectedFile = file;

    const type = this.checkExt(file.file_path);
    this.dataSource.forEach((dataFile, index) => {
      if (file === dataFile) {
        this.selectedFileIndex = index;
      }
    });

    if (type === 'Pdf') {
      this.currentFileTypeIndex = 0;
      this.selectedFileType = 'Document';
    }
    if (type === 'Video') {
      this.currentFileTypeIndex = 1;
      this.selectedFileType = 'Video';
    }
    if (type === 'Image') {
      this.currentFileTypeIndex = 2;
      this.selectedFileType = 'Image';
    }
    if (type === 'Audio') {
      this.currentFileTypeIndex = 3;
      this.selectedFileType = 'Audio';
    }

    this.selectViewerData();

  }

  public closeFileViewer() {
    this.isFileViewerOpen = false;
    this.fileName = '';
  }

  public dropped(files: NgxFileDropEntry[]) {
    if (files.length > 1) {
      alert('Please choose only one file!');
      return;
    }
    this.showUploadLoader = true;
    const droppedFile = files[0];
    const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
    const reader = new FileReader();
    fileEntry.file((file: File) => {
      const $date: Date = new Date();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.droppedFileToPreview = {
          file: file,
          file_name: droppedFile.relativePath,
          user_id: this.userProfile.id,
          file_order: '1',
          duration: '1',
          last_change_dt: $date.toISOString(),
          expire_dt: new Date($date.setDate($date.getDate() + 60 )).toISOString(),
          company_id: '2',
          level: '2',
          level_node_id: '2',
        };
        this.filesService.storeFile(this.droppedFileToPreview)
          .subscribe((res) => {
            if (res.success === 'yes') {
              this.getAllFiles();
              this.highlightingFile();
              this.showUploadLoader = false;
            }
          });
      };
    });
  }

  public highlightingFile() {
    this.highlightLastFile = true;
  }

  public fileOver(event) {
  }

  public fileLeave(event) {
  }

  public titleChange(name: string) {
    this.fileName = name;
  }

  private selectViewerData() {
    if (this.currentFileTypeIndex === 0) {
      this.filteredData = this.docs;
      this.selectedFileType = 'Document';
    }
    if (this.currentFileTypeIndex === 1) {
      this.filteredData = this.videos;
      this.selectedFileType = 'Video';
    }
    if (this.currentFileTypeIndex === 2) {
      this.filteredData = this.images;
      this.selectedFileType = 'Image';
    }
    if (this.currentFileTypeIndex === 3) {
      this.filteredData = this.audios;
      this.selectedFileType = 'Audio';
    }
  }
}
