import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-bottomrunninglinks',
  templateUrl: './bottomrunninglinks.component.html',
  styleUrls: ['./bottomrunninglinks.component.scss']
})
export class BottomrunninglinksComponent implements OnInit {
  public today = Date.now();
  public urgentInfo: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
   private dialog: MatDialog) {
  }

  ngOnInit() {


  }

}
