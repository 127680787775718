import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.development';
import { map } from 'rxjs/operators';
import { TaskUpdate } from '../models/taskUpdate.model';
import { TaskCreation } from '../models/task-creation.model';

@Injectable({ providedIn: 'root' })
export class CreateTaskService {
    constructor(private http: HttpClient) { }

    public createTask(task: TaskCreation) {
        const body = new FormData();

        if (task.videoFile) {
            body.append('file[]', this.b64toBlob(task.videoFile), 'filename');
        }
        if (task.pdfFile) {
            body.append('file2[]', '');
            body.append('file2[]', this.b64toBlob(task.pdfFile), 'filename');
        }
        if (task.imageFile) {
            body.append('file1[]', '');
            body.append('file1[]', this.b64toBlob(task.imageFile), 'filename');
        }
        body.append('measure_type_id', task.measure_type_id.toString());
        body.append('measure_des', task.measure_des);
        body.append('interval', task.interval);
        body.append('due_dt', task.due_dt.toString());
        body.append('reporter_user_id', task.reporter_user_id.toString());
        body.append('responsible_user_id', task.responsible_user_id.toString());
        body.append('remark', task.remark);
        body.append('status', task.status.toString());
        body.append('level', task.level.toString());
        body.append('company_id', task.company_id.toString());
        body.append('user_id', task.user_id.toString());
        body.append('level_node_id', task.level_node_id.toString());
        body.append('is_chat_create', task.is_chat_create.toString());
        body.append('action', 'insertMeasures');

        this.http.post<any>(environment.apiUrl, body)
            .pipe(map(taskResponse => {
                return taskResponse;
            })).subscribe(res => {

            });
    }

    public updateTask(task: TaskUpdate) {
        const body = new FormData();
        if (task.videoFile) {
            body.append('file[]', this.b64toBlob(task.videoFile), 'filename');
        }
        if (task.pdfFile) {
            body.append('file[]', '');
            body.append('file2[]', this.b64toBlob(task.pdfFile), 'filename');
        }
        if (task.imageFile) {
            body.append('file[]', '');
            body.append('file1[]', this.b64toBlob(task.imageFile), 'filename');
        }
        body.append('measure_type_id', task.measure_type_id.toString());
        body.append('measure_des', task.measure_des);
        body.append('interval', task.interval);
        body.append('due_dt', task.due_dt.toString());
        body.append('reporter_user_id', task.reporter_user_id.toString());
        body.append('responsible_user_id', task.responsible_user_id.toString());
        body.append('remark', task.remark);
        body.append('status', task.status.toString());
        body.append('level', task.level.toString());
        body.append('company_id', task.company_id.toString());
        body.append('user_id', task.user_id.toString());
        body.append('level_node_id', task.level_node_id.toString());
        body.append('is_chat_create', task.is_chat_create.toString());
        body.append('measures_id', task.measures_id.toString());
        body.append('action', 'updateMeasures');

        return this.http.post<any>(environment.apiUrl, body)
            .pipe(map(taskResponse => {
                return taskResponse;
            }))
    }

    private b64toBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'application/pdf' });
    }
}
