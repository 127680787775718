import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserProfile } from 'src/app/models/userProfile.model';

@Component({
    selector: 'app-profile-card',
    templateUrl: './profile-card.component.html',
    styleUrls: ['./profile-card.component.scss']

})
export class ProfileCardComponent implements OnInit {

    public userData: UserProfile;

    constructor(private dialogRef: MatDialogRef<ProfileCardComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.userData = data.userData;
    }

    ngOnInit() {
    }

    public onChatClick() {
        this.dialogRef.close(this.userData);
    }
}
