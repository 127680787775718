import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { TaskService } from 'src/app/services/task.service';
import { TaskUserDetailDialogComponent } from '../../task-user-detail-dialog/task-user-detail-dialog.component';
import { TaskStatusDialogComponent } from '../../task-status-dialog/task-status-dialog.component';
import { TaskFileDialogComponent } from '../../task-file-dialog/task-file-dialog.component';

@Component({
  selector: 'app-calendar-view',
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.component.scss']
})
export class CalendarViewComponent implements OnInit {
  public dataSource: any;
  public dialogRef: MatDialogRef<TaskFileDialogComponent>;
  public dialogRefA: MatDialogRef<TaskStatusDialogComponent>;
  public dialogRefB: MatDialogRef<TaskUserDetailDialogComponent>;
  public morefocus = false;
  public calendarSource: any;
  public currentDate = Date.now();
  constructor(private taskService: TaskService, private dialog: MatDialog) { }

  ngOnInit() {
    this.taskService.getUserCalendarView().subscribe(res => {
      this.calendarSource = res.data.week;
    });
    this.taskService.getMeasures('4', '2', '413', '5').subscribe(res => {
      this.dataSource = res.data.measuresData;
    });
  }

  taskUserDeatilDialog(id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      id: id
    };
    this.dialog.open(TaskUserDetailDialogComponent, dialogConfig);
    dialogConfig.disableClose = true;
  }
  moreFocusFunc() {
    this.morefocus = !this.morefocus;
  }

  getColours(date) {
    return this.taskService.getColor(date, this.currentDate);
  }


}
