import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { AdvanceoverlayComponent } from './advanceoverlay/advanceoverlay.component';
import { RoutingStateService } from '../../services/routing-state.service';
import { CordovaService } from '../../services/cordova.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public dialogConfig = new MatDialogConfig();
  public dialogRef: MatDialogRef<AdvanceoverlayComponent>;

  public loginMethod = 'email';

  constructor(
    private _router: Router,
    private dialog: MatDialog,
    private translate: TranslateService,
    private routingState: RoutingStateService,
    private cordova: CordovaService
  ) {
    translate.setDefaultLang('de');
    this.dialogConfig.data = {};
  }

  ngOnInit() {
    if (this.cordova.isNfc) {

    }

  }

  ngOnDestroy() {
    if (this.cordova.isNfc) {

    }
  }

  public welcomeHome() {
    this._router.navigate(['/']);
  }

  public loginEmail() {
    this.loginMethod = 'email';
  }

  public loginQr() {
    this.loginMethod = 'qr';
  }

  public loginNfc() {
    this.loginMethod = 'nfc';
  }

  public advanceValidate() {
    this.dialogRef = this.dialog.open(
      AdvanceoverlayComponent,
      this.dialogConfig
    );
    this.dialogRef.afterOpened().subscribe(result => {
      this.routingState.pushPopupWindow(this.closeAdvanceValidate);
    });
    this.dialogRef.afterClosed().subscribe(result => {
      this.routingState.popPopupWindow();
    });
    this.dialogRef.disableClose = true;
  }

  public closeAdvanceValidate = () => {
    this.dialogRef.close();
  }
}
