import {SelectOptions} from 'src/app/models/select-options.model';
import {Attachment} from 'src/app/models/attachment.model';
import {TypeCheckHelper} from 'src/app/helpers/typeCheck.helper';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TaskForm} from 'src/app/models/task-edit-form.model';
import {TaskCreation} from 'src/app/models/task-creation.model';
import {UserOption} from 'src/app/models/user.option.model';
import {SpeechRecognitionNativeService} from '../../../../services/speech-recognition-native.service';
import {ElementRef} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {finalize, map} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment.development';
import {HttpClient} from '@angular/common/http';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {CloseAlertComponent} from '../../activity/close-alert/close-alert.component';
import {DeviceDetectorService} from 'ngx-device-detector';

declare var Pixie: any;

export class BaseTaskManagement {
    public formGroup: FormGroup;
    public micState = {};
    private user_profile = JSON.parse(localStorage.getItem('user_profile'));
    public pixie: any;
    public closeEditor = '';
    public imageSrc = null;
    public isDesktop;

    constructor(
        public speechService: SpeechRecognitionNativeService,
        public typeCheckHelper: TypeCheckHelper,
        public snackBar: MatSnackBar,
        private http: HttpClient,
        private dialogMat: MatDialog,
        private deviceService: DeviceDetectorService
    ) {
      if (this.deviceService.isDesktop()) {
        this.isDesktop = true;
      }
      if (this.deviceService.isTablet() || this.deviceService.isMobile()) {
        this.isDesktop = false;
      }
    }

    public baseTypeOptions: SelectOptions[] = [
        { value: '1', viewValue: 'Task', icon: './assets/images/create_task_images/task.svg' },
        { value: '2', viewValue: 'Idea', icon: './assets/images/create_task_images/idea.svg' },
        { value: '3', viewValue: 'Issue', icon: './assets/images/create_task_images/issue.svg' }
    ];

    public baseFrequencyOptions: SelectOptions[] = [
        { value: 'once', viewValue: 'Once' },
        { value: 'every-day', viewValue: 'Every day' },
        { value: 'every-week', viewValue: 'Every week' },
        { value: 'every-month', viewValue: 'Every month' },
    ];

    private pdfFile;
    private imageFile;
    private videoFile;
    public selectedFiles = [];
    // private speechValue = '';
    private selectedElemForSpeechRec: ElementRef;
    private selectedFormElement: string;

    public initPixie() {
      //this.zone.runOutsideAngular(() => { performBootstrapCall(); });
      this.pixie = new Pixie({
        textureSize: 6000,
        ui: {
          visible: false,
          openImageDialog: false,
          mode: 'overlay',
          allowEditorClose: true,
          toolbar: {
            hideOpenButton: true,
            hideCloseButton: false,
          },
          nav: {
            replaceDefault: true,
            items: [
              {name: 'resize', icon: 'resize-custom', action: 'resize'},
              {name: 'crop', icon: 'crop-custom', action: 'crop'},
              {name: 'draw', icon: 'pencil-custom', action: 'draw'},
              {name: 'text', icon: 'text-box-custom', action: 'text'},
              {name: 'shapes', icon: 'polygon-custom', action: 'shapes'},
              {name: 'transform', icon: 'transform-custom', action: 'transform'}
            ]
          }
        },
        onSave: (data, name) => {
          const imageBlob = this.dataURItoBlob(data);
          const fileTmp: Attachment = {
            fileName: name,
            size: imageBlob.size,
            type: imageBlob.type,
            data: data,
            id: this.selectedFiles.length + 1,
            thumbNail: './assets/images/file_images/image_file.svg'
          };
          this.imageFile = data;
          this.selectedFiles.push(fileTmp);
          this.closeEditor = 'close';
          this.pixie.close();
          console.log('save');
        },
        onClose: () => {
          if (this.closeEditor === 'close') {
            this.closeEditor = '';
            return true;
          }
          if (this.closeEditor === 'no') {
            this.closeEditor = '';
            return false;
          }
          const dialogConfig = new MatDialogConfig();
          const dialogRef = this.dialogMat.open(CloseAlertComponent, dialogConfig);
          dialogConfig.disableClose = false;
          dialogRef.afterClosed().subscribe((data) => {
              if (data === 'save') {
                this.closeEditor = 'close';
                this.pixie.close();
                console.log('saveOnClose');
              }
              if (data) {
                this.closeEditor = 'close';
                this.pixie.close();
                console.log('saveOnCloseData');
              }
              if (!data) {
                this.closeEditor = 'no';
              }
            }
          );
          this.closeEditor = '';
          return false;
        }
      });
    }

    public onImageUploadClick() {
      this.initPixie();
      document.getElementById('image-input').click();
    }

    public onCameraClick() {
      document.getElementById('camera-input').click();
    }

    public readImageURL(event) {
      const f = event.target.files[0];
      console.log(event.target.files[0]);
      if (event.target.files && event.target.files[0]) {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          this.imageSrc = reader.result;
        };
        reader.onload = e => {
          this.imageSrc = reader.result;
          this.showPreview();
        };
      }
      event.target.value = null;
    }

    public showPreview() {
      this.pixie.openEditorWithImage(this.imageSrc);
    }

    public dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1]);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      return new Blob(
        [int8Array],
        {type: dataURI.split(',')[0].split(':')[1].split(';')[0]}
        );
    }

    public getStorageNodeId() {
      return JSON.parse(localStorage.getItem('nodeId'));
    }

    public setStorageNodeId(nodeId) {
      localStorage.setItem('nodeId', nodeId);
    }

    public _filter(name: string, options: UserOption[]): UserOption[] {
        return options.filter(option => option.name.toLowerCase().indexOf(name.toLowerCase()) > -1);
    }

    public listen(elem: ElementRef, formElement: string): void {
        if (!this.micState.hasOwnProperty(formElement)) { this.micState[formElement] = false; }
        this.micState[formElement] = !this.micState[formElement];

        if (!this.micState[formElement] && (this.selectedFormElement === formElement)) {
            this.speechService.abort();
            return;
        }

        this.selectedElemForSpeechRec = elem;
        this.selectedFormElement = formElement;

        this.speechService.start()
            .pipe(finalize(() => {
                this.micState[formElement] = false;
            }))
            .subscribe((list: string) => {
                if (list !== '') {
                    let val = this.selectedElemForSpeechRec.nativeElement.value;
                    val = val + ' ' + list;
                    const obj = {};
                    obj[formElement] = val.trim();
                    this.formGroup.patchValue(obj);
                }
            })

        ;
    }

    public isMicActive(obj: string) {
        if (!this.micState.hasOwnProperty(obj)) { this.micState[obj] = false; }
        return this.micState[obj];
    }

    public autosizeTextArea(e) {
        e.target.style.height = 'inherit';
        const computed = window.getComputedStyle(e.target);
        const height = parseInt(computed.getPropertyValue('border-top-width'), 10)
            + parseInt(computed.getPropertyValue('padding-top'), 10)
            + e.target.scrollHeight
            + parseInt(computed.getPropertyValue('padding-bottom'), 10)
            + parseInt(computed.getPropertyValue('border-bottom-width'), 10);
        e.target.style.height = height + 'px';
    }

    public onFileUploadClick() {
        this.initPixie();
        document.getElementById('file-input').click();
    }

    public async readURL(event) {
        let isExtensionValid;
        await this.typeCheckHelper.check(event).then((response) => {
            isExtensionValid = response;
        });
        if (!isExtensionValid) {
            this.showErrorMessage('Wrong file extension');
            return;
        }
        if (event.target.files) {
            for (let i = 0; i < event.target.files.length; i++) {
                const reader = new FileReader();
                reader.readAsDataURL(event.target.files[i]);
                const fileTmp: Attachment = {
                    fileName: event.target.files[i].name,
                    size: event.target.files[i].size,
                    type: event.target.files[i].type,
                    data: reader.result,
                    id: i,
                    thumbNail: './assets/images/file_images/doc_file.svg'
                };
                reader.onload = () => {
                    fileTmp.data = reader.result;

                    if (fileTmp.type.indexOf('image') > -1) {
                        fileTmp.thumbNail = fileTmp.data;
                        this.imageSrc = reader.result;
                        this.showPreview();
                        return;
                    }
                    if (fileTmp.type === 'application/pdf') {
                        this.pdfFile = fileTmp.data;
                    }
                    if (fileTmp.type.indexOf('video') > -1) {
                        this.videoFile = fileTmp.data;
                    }
                    this.selectedFiles.push(fileTmp);
                };
            }
        }
    }

    public removeAttachment(id, selectedFiles) {
        if (selectedFiles.filter(x => x.id === id).length > 0) {
            selectedFiles.splice(id, 1);
        }
        selectedFiles.forEach((file, index) => {
            file.id = index;
        });
    }

    public MapTask(createIssueForm: TaskForm, assigneeFormControl) {
      const task: TaskCreation = {
        measure_type_id: +createIssueForm.type,
        measure_des: createIssueForm.title,
        interval: 'N',
        due_dt: createIssueForm.date + ' ' + createIssueForm.time,
        reporter_user_id: this.user_profile.id,
        responsible_user_id: assigneeFormControl.value.id,
        remark: createIssueForm.description,
        status: 0,
        level: 3,
        company_id: this.user_profile.company_id,
        user_id: this.user_profile.id,
        level_node_id: this.getStorageNodeId(),
        is_chat_create: createIssueForm.enableChat,
        measures_id: null
      };
      if (this.pdfFile) {
        task.pdfFile = this.pdfFile;
      }
      if (this.videoFile) {
        task.videoFile = this.videoFile;
      }
      if (this.imageFile) {
        task.imageFile = this.imageFile;
      }
      return task;
    }

    protected showErrorMessage(message?: string) {
        if (message) {
            this.snackBar.open(message, '', {
                duration: 2000,
            });
            return;
        }
        this.snackBar.open('Error occured', '', {
            duration: 2000,
        });
    }

    public getUsers(level: string, node_id: string) {
    const body = new FormData();
    body.append('level', level);
    body.append('company_id', this.user_profile.company_id);
    body.append('user_id', this.user_profile.id);
    body.append('node_id', node_id);
    body.append('action', 'getAllMeasuresByApp');
    return this.http.post<any>(environment.apiUrl, body)
      .pipe(map(user => {
        return user;
      }));
  }
}
