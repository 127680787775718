import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  constructor() { }

  public checkInternetConnection(){
    if (navigator.onLine) {

    } else {

    }

    window.addEventListener('offline', function(e) {
      // alert('No Internet Connection')
    });

    window.addEventListener('online', function(e) {
      // alert('Connected')
    });
  }
}
