import { OnInit, Component, Inject } from '@angular/core';
import { NodesService } from 'src/app/services/nodes.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-node-dialog-task',
  templateUrl: './node-dialog-task.component.html',
  styleUrls: ['./node-dialog-task.component.scss']
})
export class NodeDialogueTaskComponent implements OnInit {
  public factory: any[] = ['Halle1', 'Machine5072', 'Machine5055', 'CNC03', 'Halle2', 'Halle3'];
  public nodeData: any[];
  public nodeTree: any[];
  public collapseSubNode = true;
  public selectedIndex: number;
  public selectedGroupIndex: number;
  public selectedsubIndex: number;
  constructor(private nodesService: NodesService, @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<NodeDialogueTaskComponent>, ) { }
  ngOnInit() {
    this.nodeData = NodesService.onGettingNodes();
    this.nodesService.getNodeTree(this.data.organization_id).subscribe(response => {
      this.nodeTree = response.tree_data;
    });

  }
  showNode(index: number) {
    this.selectedGroupIndex = index;
  }
  showSubNode(index: number) {
    this.selectedsubIndex = index;
  }

  showIndex(index: number) {
    this.selectedIndex = index;
  }
}
