import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Message } from '../models/message.model';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class MessageService {
    public url = './assets/mockData/mockMessages.json';
    public socketUrl = environment.websocketServerUrl;
    public socketConnection: WebSocket;
    public messages: Message[] = [];

    constructor(public sanitizer: DomSanitizer) { }

    public openConnection() {
        this.socketConnection = new WebSocket(this.socketUrl);
        this.socketConnection.onerror = (event) => {

        };
        this.socketConnection.onmessage = (event) => {

            const messageTmp = JSON.parse(event.data);
            messageTmp.time = new Date(messageTmp.time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

            if (messageTmp.attachments[0] != null && messageTmp.attachments[0].fileName === 'audio message') {
                messageTmp.attachments[0].data = this.sanitizer.bypassSecurityTrustResourceUrl(
                    messageTmp.attachments[0].data.changingThisBreaksApplicationSecurity);
            }

            this.messages.push(messageTmp);
        };
    }

    public getMessages() {

    }

    public sendMessage(message: Message) {
        this.socketConnection.send(JSON.stringify(message));
    }

}
