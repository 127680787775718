import {Component, DoCheck, OnInit, KeyValueDiffers} from '@angular/core';
import { TaskService } from 'src/app/services/task.service';
import { Task } from 'src/app/models/task.model';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TaskFileDialogComponent } from '../../task-file-dialog/task-file-dialog.component';
import { TaskStatusDialogComponent } from '../../task-status-dialog/task-status-dialog.component';
import { TaskUserDetailDialogComponent } from '../../task-user-detail-dialog/task-user-detail-dialog.component';
import { EditTaskComponent } from '../task-managment/edit-task/edit-task.component';
import { TaskArchiveDialogComponent } from '../task_moreOption/task-archive-dialog/task-archive-dialog.component';
import { TaskRejectDialogComponent } from '../task_moreOption/task-reject-dialog/task-reject-dialog.component';
import { TaskExportDialogComponent } from '../task_moreOption/task-export-dialog/task-export-dialog.component';
import * as XLSX from 'xlsx';
import { ViewChild, ElementRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProfileCardComponent } from '../../activity/profile-card/profile-card.component';
import { ProfileService } from 'src/app/services/profile.service';
import { UserProfile } from 'src/app/models/userProfile.model';
import { TaskFilterComponent } from '../task-managment/task-filter/task-filter.component';
import { CreateTaskComponent } from '../task-managment/create-task/create-task.component';
import { TaskUpdate } from 'src/app/models/taskUpdate.model';
import { CreateTaskService } from 'src/app/services/create-task.service';
import { TaskListFilter } from 'src/app/models/task-list-filter';
import {BaseTaskManagement} from '../task-managment/base-task-management';
import {ViewTaskComponent} from '../task-managment/view-task/view-task.component';
import {TaskRemoveDialogComponent} from '../task_moreOption/task-remove-dialog/task-remove-dialog.component';

export interface PeriodicElement {
  title: string;
  type: string;
  file: string;
  chat: string;
  progress: number;
  reporter: string;
  assignee: string;
  date: string;
  action: string;
}


@Component({
  selector: 'app-task-list-view',
  templateUrl: './task-list-view.component.html',
  styleUrls: ['./task-list-view.component.scss']
})

export class TaskListViewComponent implements OnInit, DoCheck {

  constructor(
    private taskService: TaskService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private profileService: ProfileService,
    private createTaskService: CreateTaskService,
    private differs: KeyValueDiffers,
    private baseTaskManagement: BaseTaskManagement
  ) {
    this.differ = this.differs.find({}).create();
  }
  @ViewChild('TABLE', { static: true }) table: ElementRef;
  public dataSource: Task[];
  public dialogRef: MatDialogRef<TaskFileDialogComponent>;
  public dialogRefA: MatDialogRef<TaskStatusDialogComponent>;
  public dialogRefB: MatDialogRef<TaskUserDetailDialogComponent>;
  public dialogRefEdit: MatDialogRef<EditTaskComponent>;
  public dialogRefExport: MatDialogRef<TaskExportDialogComponent>;
  public dialogRefArchive: MatDialogRef<TaskArchiveDialogComponent>;
  public dialogRefReject: MatDialogRef<TaskRejectDialogComponent>;
  public tasks: Task[] = [];
  public displayedColumns: string[] = [
    'title',
    'type',
    'progress',
    'file',
    // 'chat',
    'reporter',
    'assignee',
    'date',
    'action'];
  public userData: UserProfile;
  public showChat = false;
  public showArchivedTasks = localStorage.getItem('showArchivedTasks') && localStorage.getItem('showArchivedTasks') === 'yes';

  public currentPage: number;
  public pageSize = 25;
  public pageCount: number[];
  public allTasks: Task[];
  public maximumSize: number;
  public pageContent: Task[];
  public source;
  public allData;
  private nodeId;
  private userProfile = JSON.parse(localStorage.getItem('user_profile'));

  private filterData: TaskListFilter;
  private currentOpenSlider: Task;
  differ: any;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.nodeId = this.baseTaskManagement.getStorageNodeId();
    this.getData();
    this.initFilterData();
  }

  ngDoCheck() {
    const change = this.baseTaskManagement.getStorageNodeId();
    if (change !== this.nodeId) {
        this.setNodeId(change);
        this.getData();
    }
  }

  private setNodeId(nodeId) {
    this.nodeId = nodeId;
  }

  taskStatusDialog(task: Task, i: Number) {

    if (this.currentOpenSlider && task !== this.currentOpenSlider) {
      this.currentOpenSlider.showPopup = false;
    }
    if (task.showPopup) {
      task.showPopup = false;
    } else {
      this.currentOpenSlider = task;
      task.showPopup = true;
    }
  }

  public changeSlider(event, element) {
    this.updateStatus(element, event.value);
  }

  taskFileDialog(fileTask: Task) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      fileTask: fileTask
    };
    this.dialog.open(TaskFileDialogComponent, dialogConfig);
    dialogConfig.disableClose = true;
  }

  taskUserDeatilDialog(id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      id: id
    };
    this.dialog.open(TaskUserDetailDialogComponent, dialogConfig);
    dialogConfig.disableClose = true;
  }

  showArchived() {
    this.clearFilter();
    if (!this.showArchivedTasks) {
      this.getData();
    } else {
      this.filterByArchived();
    }
    this.showArchivedTasks = !this.showArchivedTasks;
    localStorage.setItem('showArchivedTasks', '');
    if (this.showArchivedTasks) {
      localStorage.setItem('showArchivedTasks', 'yes');
    } else {
      localStorage.setItem('showArchivedTasks', 'no');
    }
  }

  archiveTask(task) {
    this.taskService.archiveTask(task)
      .subscribe((res) => {
        if (res.success) {
          this.dataSource.forEach((element, index) => {
            if (element.id === task.id) {
              this.dataSource[index]['is_archived'] = res.data['is_archived'];
              if (res.data['is_archived'] == 1 && !this.showArchivedTasks) {
                this.filterByArchived();
              }
            }
          });
        }
      });
    // const dialogConfig = new MatDialogConfig();
    // this.dialogRefArchive = this.dialog.open(TaskArchiveDialogComponent, dialogConfig);
  }

  rejectTask(action, obj) {
    obj.action = action;
    const dialogRefReject = this.dialog.open(TaskRejectDialogComponent, {

      data: obj
    });
    dialogRefReject.afterClosed().subscribe(result => {
      if (result.event === 'Delete') {
        this.deleteRowData(result.data);
      }
    });
  }

  exportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'SheetJS.xlsx');
  }

  public openTaskPopup(element) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      data: element
    };
    this.dialog.open(ViewTaskComponent, dialogConfig);
    dialogConfig.disableClose = true;
  }

  public openCreateTaskPopup() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'custom-modal-issuee';
    const dialogRef = this.dialog.open(CreateTaskComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getData();
      }
    });

  }

  public removeTask(task) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      task: task
    };
    const dialogRef = this.dialog.open(TaskRemoveDialogComponent, dialogConfig);
    dialogConfig.disableClose = true;
    dialogRef.afterClosed().subscribe((data) => {
      if (data === 'delete') {
        this.taskService.removeTask(task)
          .subscribe((res) => {
            if (res.success) {
              this.dataSource.forEach((element) => {
                if (element.id === task.id) {
                  this.deleteRowData(element);
                }
              });
            }
          });
      }
    });
  }

  deleteRowData(row_obj) {
    this.dataSource = this.dataSource.filter((value, key) => {
      return value.id !== row_obj.id;
    });
  }

  initFilterData() {
    this.filterData = {
      assignee: '',
      creationDate: '',
      creationTime: '',
      dueDate: '',
      dueTime: '',
      keyWords: '',
      reporter: '',
      type: '',
      enableArchivedTasks: false
    };
  }

  public openFilter() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      tasks: this.allData,
      filterData: this.filterData
    };
    const dialogRef = this.dialog.open(TaskFilterComponent, dialogConfig);
    dialogConfig.disableClose = true;
    dialogRef.afterClosed().subscribe((data) => {
      if (data && data !== 'clear' && data !== undefined) {
        this.filterData = data;
        this.filter(data);
      }
      if (data === 'clear') {
        this.initFilterData();
        this.clearFilter();
        this.filter(this.filterData);
        return;
      }
    });
  }

  public openChat(data: UserProfile) {
    this.userData = data;
    this.showChat = true;
    document.getElementById('chat');
  }

  public openEdit(element) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      data: element
    };
    const dialogRef = this.dialog.open(EditTaskComponent, dialogConfig);
    dialogConfig.disableClose = true;
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getData();
      }
    });
  }

  public initPageContent(elements) {
    const amountOfPages = Math.ceil(this.allTasks.length / this.pageSize);
    this.pageCount = Array(amountOfPages).fill(0).map((x, i) => i);
    return this.pageContent = elements.slice(0, this.pageSize);
  }

  public changePage(page: number) {
    if (page < 1 || page > this.pageCount.length || this.pageCount.length === 1) {
      return;
    }
    this.currentPage = page;
    page = page - 1;
    this.pageContent = this.allTasks.slice((page * this.pageSize), ((page * this.pageSize) + this.pageSize));
    this.dataSource = this.pageContent;
  }

  public goToFirstPage() {
    this.changePage(1);
  }

  public goToLastPage() {
    this.changePage(this.pageCount.length);
  }

  public showProfile(username: string, profileId: number) {
    this.profileService.getProfileById(profileId).subscribe((data) => {
      const tmpData = data;
      tmpData.profileId = profileId;
      tmpData.name = username;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        userData: tmpData
      };
      const dialogRef = this.dialog.open(ProfileCardComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((data) => {
        if (data) {
          this.openChat(tmpData);
        }
      });
    }, error => {
      this.showErrorMessage('Can`t receive user data');
    });
  }


  private getData() {
    this.taskService.getMeasures('4', this.userProfile.company_id, this.userProfile.id, this.nodeId).subscribe(res => {

      res.data.measuresData.forEach(element => {
        element.style = {
          'width': element.status + '%',
          'background-color': this.taskService.getColor(element.due_dt, Date.now())
        };
        if (element) {
          element.displayType = this.getType(element.measure_type_id);
        }
        element.showPopup = false;
      });

      let tmp = [];
      tmp = res.data.measuresData;
      this.allData = res.data;
      this.currentPage = 1;
      this.maximumSize = tmp.length;
      this.allTasks = tmp;
      this.source = tmp;
      this.dataSource = this.initPageContent(tmp);

      if (!this.showArchivedTasks) {
        this.filterByArchived();
      }
    });
  }

  filterByArchived() {
    this.dataSource = this.dataSource.filter((value, key) => {
      return value['is_archived'] == 0;
    });
  }

  private showErrorMessage(message?: string) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 2000,
      });
      return;
    }
    this.snackBar.open('Error occured', '', {
      duration: 2000,
    });
  }

  public clearFilter() {
    this.dataSource = this.source;
    this.initFilterData();
  }

  public chatsCleared() {
    this.showChat = false;
  }

  private filter(filterCriteria) {
    let array = this.source;
    if (!filterCriteria) {
      return;
    }
    if (filterCriteria.type !== '') {
      array = array.filter( x => {
        return x.measure_type_id === filterCriteria.type;
      });
    }
    if (filterCriteria.assignee !== '') {
      array = this.source.filter(x => x.responsible_name.indexOf(filterCriteria.assignee) > -1);
    }
    if (filterCriteria.reporter !== '') {
      array = array.filter(x => x.reporter_name.indexOf(filterCriteria.reporter) > -1);
    }
    if (filterCriteria.dueDate !== '') {
      array = array.filter(x => x.due_dt.split(' ')[0] === filterCriteria.dueDate);
    }
    if (filterCriteria.dueTime !== '') {
      array = array.filter(x => x.due_dt.split(' ')[1].split(':')[0] +
        ':' + x.due_dt.split(' ')[1].split(':')[1] === filterCriteria.dueTime);
    }
    if (filterCriteria.creationDate !== '') {
      array = array.filter(x => x.dt.split(' ')[0] === filterCriteria.creationDate);
    }
    if (filterCriteria.creationTime !== '') {
      array = array.filter(x => x.dt.split(' ')[1].split(':')[0] +
        ':' + x.dt.split(' ')[1].split(':')[1] === filterCriteria.creationTime);
    }
    if (filterCriteria.keyWords && filterCriteria.keyWords.replace(/\s/g, '').length !== 0) {
      const tmp = [];
      const text = filterCriteria.keyWords.split(' ');
      for (let i = 0; i < text.length; i++) {
        tmp.push(array.filter(x => {
           return x.measure_des.indexOf(text[i]) > -1;
        }));
      }
      tmp.forEach(element => {
        array = element;
      });
    }
    if (filterCriteria.enableArchivedTasks !== undefined) {
      if (filterCriteria.enableArchivedTasks) {
        // array = array.filter((value, key) => {
        //   return value['is_archived'] == 1;
        // });
        // array = this.source;
      } else {
        array = array.filter((value, key) => {
          return value['is_archived'] == 0;
        });
      }
    }
    this.dataSource = [];
    this.dataSource = array;
  }

  private getType(id) {
    switch (id) {
      case '1': return 'Task';
      case '2': return 'Idea';
      case '3': return 'Issue';
    }
  }

  private updateStatus(task, status) {
    const element = this.MapTask(task, status);
    this.createTaskService.updateTask(element).subscribe((data) => {
      this.getData();
    });
  }

  private MapTask(element: Task, status: number) {

    const task: TaskUpdate = {
      measure_type_id: +element.measure_type_id,
      measure_des: element.measure_des,
      interval: element.interval,
      due_dt: element.due_dt,
      reporter_user_id: +element.reporter_user_id,
      responsible_user_id: element.responsible_user_id,
      remark: element.remark,
      status: status,
      level: 3,
      company_id: +element.company_id,
      user_id: +element.user_id,
      level_node_id: 5,
      is_chat_create: true,
      measures_id: +element.id
    };
    return task;
  }

  sortData(event) {
    let field = '';
    switch (event.active) {
      case 'title': field = 'measure_des';
        break;
      case 'type': field = 'measure_type';
        break;
      case 'reporter': field = 'reporter_name';
        break;
      case 'assignee': field = 'responsible_name';
        break;
      case 'progress': field = 'status';
        break;
      case 'date': field = 'due_dt';
        break;

      default: return;
    }
    this.dataSource = this.dataSource.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0));
    if (event.direction === 'desc') {
      this.dataSource.reverse();
    }
    this.dataSource = this.dataSource.filter(() => {
      return true;
    });
  }
}
