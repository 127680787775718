import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { PrivacyPolicyDialogComponent } from './privacy-policy-dialog/privacy-policy-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  public show: Boolean = false;
  public selectedList = '';
  public dialogRef: MatDialogRef<PrivacyPolicyDialogComponent>;
  
  constructor(private _router: Router,private dialog:MatDialog,private translate:TranslateService) { }

  ngOnInit() {
  }
  loginScreen() {
    this._router.navigate(['/login']);
  }

  policyDisplay() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
    }
    this.dialog.open(PrivacyPolicyDialogComponent, dialogConfig);
    dialogConfig.disableClose =true;
  }
}
