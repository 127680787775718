import { Injectable, ComponentFactoryResolver } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TypeCheckHelper {

    private type: string;
    private extension: string;
    private _event: any;

    private jpegSignatures = ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3', 'ffd8ffe8', 'ffd8ffdb'];
    private gifSignatures = ['47494638'];
    private pngSignatures = ['89504e47'];

    private pdfSignatures = ['255044462d'];
    private docSignatures = ['d0cf11e0a1b11ae1'];
    private xlsSignatures = this.docSignatures;
    private pptSignatures = this.docSignatures;
    private docxSignatures = ['504b0304'];
    private pptxSignatures = this.docxSignatures;
    private xlsxSignatures = this.docxSignatures;
    private zipSignatures = ['630000000000', '504B0506', '504b34', '504b0304'];
    private epubSignatures = this.docxSignatures;
    private rtfSignatures = ['7b5c72746631'];
    private sevenZSignatures = ['377ABCAF271C'];
    private rarSignatures = ['526172211A0700', '526172211A070100'];
    private mobiSignatures = ['424F4F4B4D4F4249'];
    private fb2Signatures = ['3C3F786D6C207665'];
    private djvuSignatures = ['41542654464F52'];

    private mp3Signatures = ['fffb', '494433', 'ffex', 'fffx'];
    private m4aSignatures = ['667479704d344120', '667479704D3441'];
    private wavSignatures = ['52494646', '57415645666d7420', '1a45dfa3'];
    private mp4Signatures = ['667479704d534e56', '6674797069736f6d', '00018'];
    private m4vSignatures = ['667479706d703432', '667479704d345620'];
    private mpgSignatures = ['ffex', 'fffx', '000001ba', '000001bx'];
    private wmvSignatures = ['3026B2758E66CF11', 'A6D900AA0062CE6C'];
    private movSignatures = ['6674797071742020', '71742020', '6D646174'];
    private aviSignatures = ['415649204C495354', '564920'];
    private swfSignatures = ['435753', '465753', '5B47656E6572616C', '5D0D0A446973706C', '6179204E616D653D',
        '3C446973706C6179', '4E616D65', '46726F6D202020', '46726F6D203F3F3F', '46726F6D3A20', '43616C63756C7578', '20496E646F6F7220'];


    async check(event) {
        this._event = event;
        return new Promise<boolean>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(event.target.files[0]);
            reader.onload = async () => {

                const arr = reader.result as ArrayBuffer;
                const uint8View = new Uint8Array(arr).subarray(0, 4);
                let header = '';
                for (let i = 0; i < uint8View.length; i++) {
                    header += uint8View[i].toString(16);
                }

                this.type = event.target.files[0].type.substring(0, event.target.files[0].type.indexOf('/'));
                this.extension = event.target.files[0].type.substring(event.target.files[0].type.indexOf('/') + 1,
                    event.target.files[0].type.length);
                let res;
                if (this.type === 'image') {
                    res = this.checkImageFormats(header);
                }
                if (this.type === 'application') {
                    res = this.checkApplicationFormats(header);
                }
                if (this.type === 'text') {
                    res = await this.checkTextFormats();
                }
                if (this.type === 'audio' || this.type === 'video') {
                    res = this.checkMediaFormats(header);
                }

                resolve(res);
            };
        });
    }

    private checkImageFormats(header) {
        switch (this.extension) {
            case 'jpeg':
            case 'jpg': return this.jpegSignatures.filter(x => x.includes(header)).length > 0 ? true : false;
            case 'gif': return this.gifSignatures.filter(x => x.includes(header)).length > 0 ? true : false;
            case 'png': return this.pngSignatures.filter(x => x.includes(header)).length > 0 ? true : false;
            default: return false;
        }
    }

    private checkApplicationFormats(header) {
        switch (this.extension) {
            case 'pdf': return this.pdfSignatures.filter(x => x.includes(header)).length > 0 ? true : false;
            case 'doc': return this.docSignatures.filter(x => x.includes(header)).length > 0 ? true : false;
            case 'xls': return this.xlsSignatures.filter(x => x.includes(header)).length > 0 ? true : false;
            case 'ppt': return this.pptSignatures.filter(x => x.includes(header)).length > 0 ? true : false;
            case 'pptx': return this.pptxSignatures.filter(x => x.includes(header)).length > 0 ? true : false;
            case 'xlsx': return this.xlsxSignatures.filter(x => x.includes(header)).length > 0 ? true : false;
            case 'x-zip-compressed': return this.zipSignatures.filter(x => x.includes(header)).length > 0 ? true : false;
            case 'epub': return this.epubSignatures.filter(x => x.includes(header)).length > 0 ? true : false;
            case 'rtf': return this.rtfSignatures.filter(x => x.includes(header)).length > 0 ? true : false;
            case 'x-7z-compressed': return this.sevenZSignatures.filter(x => x.includes(header)).length > 0 ? true : false;
            case 'x-rar-compressed': return this.rarSignatures.filter(x => x.includes(header)).length > 0 ? true : false;
            case 'mobi': return this.mobiSignatures.filter(x => x.includes(header)).length > 0 ? true : false;
            case 'fb2': return this.fb2Signatures.filter(x => x.includes(header)).length > 0 ? true : false;
            case 'djvu': return this.djvuSignatures.filter(x => x.includes(header)).length > 0 ? true : false;
            default: return false;
        }
    }

    private async checkTextFormats() {
        return new Promise<boolean>((resolve) => {
            const reader2 = new FileReader();
            reader2.readAsText(this._event.target.files[0]);
            reader2.onload = (e: any) => {
                const t = e.target.result;
                const f = t.substring(0, 2);
                if (f.includes('MZ')) {
                    resolve(false);
                    return false;
                }
                resolve(true);
            }
        });
    }

    private checkMediaFormats(header) {
        switch (this.extension) {
            case 'mp3': return this.mp3Signatures.filter(x => x.includes(header)).length > 0 ? true : false;
            case 'm4a': return this.m4aSignatures.filter(x => x.includes(header)).length > 0 ? true : false;
            case 'wav': return this.wavSignatures.filter(x => x.includes(header)).length > 0 ? true : false;
            case 'mp4': return this.mp4Signatures.filter(x => x.includes(header)).length > 0 ? true : false;
            case 'm4v': return this.m4vSignatures.filter(x => x.includes(header)).length > 0 ? true : false;
            case 'mpg': return this.mpgSignatures.filter(x => x.includes(header)).length > 0 ? true : false;
            case 'wmv': return this.wmvSignatures.filter(x => x.includes(header)).length > 0 ? true : false;
            case 'mov': return this.movSignatures.filter(x => x.includes(header)).length > 0 ? true : false;
            case 'avi': return this.aviSignatures.filter(x => x.includes(header)).length > 0 ? true : false;
            case 'swf': return this.swfSignatures.filter(x => x.includes(header)).length > 0 ? true : false;
            default: return false;
        }
    }

}
