import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TaskExportDialogComponent } from '../../dashboard/task/task_moreOption/task-export-dialog/task-export-dialog.component';
import { CreateTaskComponent } from '../../dashboard/task/task-managment/create-task/create-task.component';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public show: Boolean = false;
  public isList: boolean = true;
  public isResponsibility: boolean = true;
  public isReport: boolean = true;
  public dialogRef: MatDialogRef<CreateTaskComponent>;
  public dialogRefExport: MatDialogRef<TaskExportDialogComponent>;
  @Output() classEvent = new EventEmitter<Boolean>();
  constructor() { }
  ngOnInit() {
    this.classEvent.emit(this.show);
  }

  public showMenu() {
    this.show = !this.show;
    this.classEvent.emit(this.show);
  }
  public list() {
    this.isList = !this.isList;
  }
  public responsiblity() {
    this.isResponsibility = !this.isResponsibility;
  }
  public report() {
    this.isReport = !this.isReport;
  }

  exportTask() {
    
  }

  createTask() {

  }
}
