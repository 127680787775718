import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-responsibility',
  templateUrl: './my-responsibility.component.html',
  styleUrls: ['./my-responsibility.component.scss']
})
export class MyResponsibilityComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
