import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { fromEvent } from 'rxjs';
import { Router } from '@angular/router';
import { RoutingStateService } from './routing-state.service';

function _window(): any {
    return window;
}

@Injectable({
    providedIn: 'root'
})

export class CordovaService implements OnDestroy {

    private resume: BehaviorSubject<boolean>;

    private isQrCodePluginActive = false;
    private isNfcCalbackRegistered = false;
    private isNfcStatusCheck = false;
    private nfcCallbacks: Array<ICordovaAnyCallback> = [];
    private nfcStatusCallbacks: Array<ICordovaAnyCallback> = [];
    private timeoutNfcCheckStatus: any;

    constructor(
        private zone: NgZone
        , private router: Router
        , private routingState: RoutingStateService
    ) {
        this.resume = new BehaviorSubject<boolean>(null);
        fromEvent(document, 'resume').subscribe(event => {
            this.zone.run(() => {
                this.onResume();
            });
        });

        fromEvent(document, 'backbutton').subscribe(event => {
            this.zone.run(() => {
                this.backmenuClick();
            });
        });

    }

    ngOnDestroy(): void {
        if (this.timeoutNfcCheckStatus) {
            clearTimeout(this.timeoutNfcCheckStatus);
        }
    }

    get cordova(): any {
        return _window().cordova;
    }

    get nfc(): any {
        return _window().nfc;
    }

    get navigator(): any {
        return _window().navigator;
    }

    get device(): any {
        return _window().device;
    }

    get plugins(): any {
        return this.cordova.plugins;
    }

    get barcodeScanner(): any {
        return this.plugins.barcodeScanner;
    }

    get isDevice(): boolean {
        return !!_window().device;
    }

    get isNavigator(): boolean {
        return !!_window().navigator;
    }

    get isCordova(): boolean {
        return !!_window().cordova;
    }

    get isNfc(): boolean {
        return !!_window().nfc;
    }

    get isPlugins(): boolean {
        if (this.isCordova) {
            return !!this.cordova.plugins;
        }
        return false;
    }

    get isBarcodeScanner(): any {
        if (this.isPlugins) {
            return !!this.plugins.barcodeScanner;
        }
        return false;
    }

    public onResume(): void {
        this.resume.next(true);
    }

    public hideSplashScreen(): void {
        if (this.isNavigator) {
            if (!!this.navigator.splashscreen) {
                this.navigator.splashscreen.hide();
            }
        }
    }

    public backmenuClick(): void {
        if (this.isQrCodePluginActive) {
            this.isQrCodePluginActive = false;
        } else
            if (this.routingState.getPopupWindowCount() > 0) {
                this.routingState.runPopupWindowCloseCallback();
            } else {
                switch (this.routingState.getCurrentUrl()) {
                    case '/':
                    case '/login':
                        if (this.isCordova) {
                            this.navigator.app.exitApp();
                        }
                        break;
                }
                this.router.navigate([this.routingState.getPreviousUrl()]);
            }
    }

    public scanQrCode(callback: ICordovaCallback): void {
        this.isQrCodePluginActive = true;
        this.barcodeScanner.scan(
            (result) => {
                if (!result.cancelled) {
                    this.isQrCodePluginActive = false;
                    const message = 'Suppose to login with this QR...\n' +
                        result.text;
                    callback(null, message);
                }
            },
            (error) => {
                const message = 'Scanning failed: ' + error;
                callback(new Error(message));
                this.isQrCodePluginActive = false;
            },
            {
                preferFrontCamera: false,
                showFlipCameraButton: true,
                showTorchButton: true,
                prompt: 'Place a barcode inside the scan area',
                resultDisplayDuration: 500,
                formats: 'QR_CODE',
                disableSuccessBeep: false
            }
        );
    }

    public registerNfcScan(callback: ICordovaAnyCallback) {
        if (callback) {
            if (this.nfcCallbacks.indexOf(callback) === -1) {
                this.nfcCallbacks.push(callback);
            }
        }
        this.registerNfcListener();
    }

    public unregisterNfcScan(callback: ICordovaAnyCallback) {
        if (callback) {
            const newList: Array<ICordovaAnyCallback> = this.nfcCallbacks.filter((value) => {
                return (value !== callback);
            });
            this.nfcCallbacks = newList;
        }
    }

    public registerNfcStatusCallback(callback: ICordovaAnyCallback) {
        if (this.nfcStatusCallbacks.indexOf(callback) === -1) {
            this.nfcStatusCallbacks.push(callback);
        }
    }
    public unregisterNfcStatusCallback(callback: ICordovaAnyCallback) {
        const newList: Array<ICordovaAnyCallback> = this.nfcStatusCallbacks.filter((value) => {
            return (value !== callback);
        });
        this.nfcStatusCallbacks = newList;
    }

    private registerNfcListener() {
        if (this.isNfcCalbackRegistered) { return; }

        if (this.isNfc) {
            this.nfc.addNdefListener(
                this.nfcCallback,
                () => {
                    this.isNfcCalbackRegistered = true;
                    this.nfcStatusCallback('NFC_ACTIVE');
                },
                this.nfcStatusCallback,
            );
        }
    }

    private nfcCallback = (tag: any) => {
        this.zone.run(() => {
            this.nfcCallbacks.forEach((callback) => {
                if (callback) { callback(tag); }
            });
        });
    }

    private nfcStatusCallback = (tag: any) => {
        if (this.timeoutNfcCheckStatus) {
            clearTimeout(this.timeoutNfcCheckStatus);
            this.timeoutNfcCheckStatus = false;
        }

        this.zone.run(() => {
            this.nfcStatusCallbacks.forEach((callback) => {
                if (callback) { callback(tag); }
            });
        });

        if (this.isNfcStatusCheck) {
            if (this.nfcStatusCallbacks.length > 0) {
                this.timeoutNfcCheckStatus = setTimeout(() => {
                    this.nfcStatusCheck();
                }, 1000);
            }
        }

        this.zone.run(() => {
            setTimeout(() => {
                this.registerNfcListener();
            }, 0);

        });
    }


    private nfcStatusCheck(): void {
        if (this.isNfc) {
            this.nfc.enabled(() => {
                this.nfcStatusCallback('NFC_ACTIVE');
            }, this.nfcStatusCallback);
        }
    }

    public setNfcStatusCheck(value: boolean): void {
        this.isNfcStatusCheck = value;
        if (this.isNfcStatusCheck) {
            this.nfcStatusCheck();
        }
    }
}

export interface ICordovaCallback {
    (error: Error, result?: string): void;
}

export interface ICordovaAnyCallback {
    (result: any): void;
}

export interface ICordovaBackmenuCallback {
    (): void;
}
