import {Injectable, NgZone, OnDestroy} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {CordovaService} from './cordova.service';
import {TranslateService} from '@ngx-translate/core';

interface IWindow extends Window {
    webkitSpeechRecognition: any;
}

@Injectable({providedIn: 'root'})
export class SpeechRecognitionNativeService implements OnDestroy {

    private speechService = null;
    private isSpeechServiceActive = false;
    private currentSpeechObserver: Observer<string>;

    constructor(private zone: NgZone
                , private cordova: CordovaService
                , private translate: TranslateService
                ) {
        this.speechService = null;

        if (!this.cordova.isCordova) {
            try {
                const { webkitSpeechRecognition }: IWindow = <IWindow>window;
                this.speechService = new webkitSpeechRecognition();
                this.speechService.continuous = false;  // ? true
                this.speechService.interimResults = false; // ? true;
                this.speechService.lang = translate.defaultLang; // 'en-US';  // "de-DE"
                console.log("translate.defaultLang: "+translate.defaultLang);
                this.speechService.maxAlternatives = 1; // ? 10
                // this.speechService.grammars
                // this.speechService.serviceURI

                this.speechService.onend = () => {
                    if (this.isSpeechServiceActive) {
                        this.speechService.start();
                    }
                };
                this.speechService.onresult = (event) => {
                    const speechToText = event.results[0][0].transcript;
                    if (this.currentSpeechObserver != null) {
                        this.zone.run(() => this.currentSpeechObserver.next(speechToText));
                    }
                };
                // this.speechService.onerror = (event) => {
                //     console.log('Speech recognition error detected: ' + event.error);
                //     if (this.currentSpeechObserver != null) {
                //         this.currentSpeechObserver.error(event.error);
                //     }
                // };

                // this.speechService.onaudiostart = () => {
                //     console.log('Speech recognition service onaudiostart');
                // };
                // this.speechService.onaudioend = () => {
                //     console.log('Speech recognition service onaudioend');
                // };
                // this.speechService.onnomatch = () => {
                //     console.log('Speech recognition service onnomatch');
                // };
                // this.speechService.onsoundstart = () => {
                //     console.log('Speech recognition service onsoundstart');
                // };
                // this.speechService.onsoundend = () => {
                //     console.log('Speech recognition service onsoundend');
                // };
                // this.speechService.onspeechstart = () => {
                //     console.log('Speech recognition service onspeechstart');
                // };
                // this.speechService.onspeechend = () => {
                //     console.log('Speech recognition service onspeechend');
                // };
                // this.speechService.onstart = () => {
                //     console.log('Speech recognition service onstart');
                // };

            } catch (e) {
                this.speechService = null;
            }
        }
    }

    public start(): Observable<string> {
        this.isSpeechServiceActive = true;

        return Observable.create(observer => {
            this.currentSpeechObserver = observer;

            if (this.speechService != null) {
                if (this.translate.currentLang) {
                    this.speechService.lang = this.translate.currentLang;
                } else {
                    this.speechService.lang = this.translate.defaultLang;
                }
                this.speechService.start();
            }
        });
    }

    public stop() {
        this.isSpeechServiceActive = false;
        if (this.speechService != null) {
            this.speechService.stop();
        }
        if (this.currentSpeechObserver != null) {
            this.currentSpeechObserver.complete();
            this.currentSpeechObserver = null;
        }
    }

    public abort() {
        this.isSpeechServiceActive = false;
        if (this.speechService != null) {
            this.speechService.abort();
        }
        if (this.currentSpeechObserver != null) {
            this.currentSpeechObserver.complete();
            this.currentSpeechObserver = null;
        }
    }

    public isNotNative() {
        if (this.cordova.isCordova) {
            return false;
        }
        return (this.speechService != null);
    }

    ngOnDestroy(): void {
    }
}

