import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { PictureCameraComponent } from './picture-camera/picture-camera.component';
@Component({
  selector: 'app-picture-annotation',
  templateUrl: './picture-annotation.component.html',
  styleUrls: ['./picture-annotation.component.scss']
})
export class PictureAnnotationComponent implements OnInit {
  
  @ViewChild("video", { static: false })
  public video: ElementRef;

  constructor(private dialog:MatDialog) {

   }

  ngOnInit() {
  }
  public openCam(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      imageUrl: '',
      type: 1
    }
    const dialogRef = this.dialog.open(PictureCameraComponent, dialogConfig);
    dialogConfig.disableClose =true;

    dialogRef.afterClosed().subscribe(result => {
      this.video.nativeElement.pause();
    });

  }

  public onSelectFile( e ) {
    const file = e.target.files[0];

    const _this = this;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {

      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        imageUrl: reader.result,
        type: 2
      }

      _this.dialog.open(PictureCameraComponent, dialogConfig);
      dialogConfig.disableClose =true;
    };
    reader.onerror = function (error) {
      
    };
  }
}