import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { User } from '../models/user.model';
import { throwError as observableThrowError } from 'rxjs';
import {NodesService} from './nodes.service';
import {environment} from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public fileUrl: 'https://dev.scip.website/SmartlySolutions/API/data';
    public currentUser: Observable<User>;
    public rememberMe: boolean;
    private currentUserSubject: BehaviorSubject<User>;
    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();

    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;

    }

    public login(username: string, password: string) {
        const body = new FormData();
        body.append('username', username);
        body.append('pwd', password);
        body.append('action', 'login');
        return this.http.post<any>(environment.apiUrl, body)
            .pipe(map(user => {
              localStorage.setItem('showArchivedTasks', 'yes');
              if (user && user.token) {
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }
                if (localStorage.getItem('currentNode') === '') {
                    localStorage.setItem('currentNode', NodesService.onGettingNodes()[0].display_name);
                    localStorage.setItem('nodeId', NodesService.onGettingNodes()[0].id);
                }
                return user;
            }));
    }

    public logout() {
      localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }


    public forgotPassword(email) {
        const body = new FormData();
        body.append('username', email);
        body.append('action', 'forgot');
        return this.http.post<any>(`${environment.apiUrl}`, body).pipe(
            map((res) => {
                return res;
            }),
            catchError(err => {
                return observableThrowError(err || 'Server error');
            }));
    }




}
