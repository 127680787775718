import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FileFromAPI } from 'src/app/models/file.model';

@Component({
    selector: 'app-file-viewer',
    templateUrl: 'file-viewer.component.html',
    styleUrls: ['file-viewer.component.scss']
})

export class FileViewerComponent implements OnInit, OnDestroy {
    @Output() titleChange = new EventEmitter();
    @Input() file: FileFromAPI;
    @Input() selectedFileType: string;
    @Input() set data(value: FileFromAPI[]) {
        if (!value[0]) {
            this.fileType = '';
            this.filesDoNotExist = true;
            this.titleChange.emit(this.selectedFileType);
        } else {
            this.filesDoNotExist = false;
            this.selectedData = value;
            this.initViewer();
        }
    }

    public selectedData: FileFromAPI[] = [];
    public fileType = '';
    public fileIndex = 0;
    public filesDoNotExist = false;

    constructor() {

    }

    ngOnInit() {
        this.initViewer();
    }

    public changeImageName(e) {
        this.titleChange.emit(this.selectedData[e].file_name);
    }

    public prevFile() {
        if (this.fileIndex > 0) {
            this.fileIndex--;
        } else {
            this.fileIndex = this.selectedData.length - 1;
        }
        this.titleChange.emit(this.selectedData[this.fileIndex].file_name);
    }

    public nextFile() {
        if (this.fileIndex < this.selectedData.length - 1) {
            this.fileIndex++;
        } else {
            this.fileIndex = 0;
        }
        this.titleChange.emit(this.selectedData[this.fileIndex].file_name);
    }

    private initViewer() {
        this.fileIndex = 0;
        this.fileType = this.checkType(this.selectedData[0].file_path);
        this.selectedData.forEach((file, i) => {
            if (file === this.file) {
                this.fileIndex = i;
            }
        });
        if (this.selectedData[this.fileIndex]) {
            this.titleChange.emit(this.selectedData[this.fileIndex].file_name);
        } else {
            this.titleChange.emit(this.selectedData[0].file_name);
        }

    }

    private checkType(link) {
        const linkArr = link.split('/');
        const filename = linkArr[linkArr.length - 1];
        const fileNameArr = filename.split('.');
        const fileExt = fileNameArr[fileNameArr.length - 1].toLowerCase();
        let type = 'Invalid';

        if (fileExt === 'jpg' || fileExt === 'jpeg' || fileExt === 'png' || fileExt === 'gif') {
            type = 'Image';
        }
        if (fileExt === 'mp4' || fileExt === 'avi'
          || fileExt === 'mov' || fileExt === 'mpg' || fileExt === 'm4v' || fileExt === 'swf'
          || fileExt === 'wmv' || fileExt === 'webm'
        ) {
            type = 'Video';
        }
        if (fileExt === 'pdf') {
            type = 'Pdf';
        }
        if (fileExt === 'mp3' || fileExt === 'wav' || fileExt === 'm4a') {
            type = 'Audio';
        }

        return type;
    }

    ngOnDestroy() {
        this.fileType = '';
    }
}
